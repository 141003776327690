const probe4pa = () => {
    return (
    <p>
        <br /><b>Частота:</b> 1.0-6.0 МГц<br /><b>Количество элементов:</b> 80 элемента<br />
        <b>Клиническое применение:</b> кардиология, транскраниальные исследования, абдоминальные исследования.
        <br /><br />            
    </p>
    );
}

export default probe4pa;