const unilight = () => {
   return (
      <p>      
         Оптический наконечник, с торцевым выходом излучения под углом 22°, предназначен для проведения бесконтактных, наружных контактных и внутритканевых вмешательств.                 
         <br /><br /> 
         <strong>Длина:</strong> 40мм / 100мм / 200мм
         <br /><br />  
         <strong>Область применения:</strong>
         <br />
         Хирургия доброкачественных новообразований<br />  
         Проктология<br />            
      </p>
   );
}

export default unilight;