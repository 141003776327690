const e3 = () => {
   return (
      <p>
         Модель E3 создана на базе производительной платформы PAD - такая же используется в бестселлере портативного рынка SonoScape S2N. Превосходная серошкальная визуализация и чувствительный допплер нового портативного аппарата SonoScape E3 обеспечивают качественную диагностику по доступной цене. Отличительной чертой E3 является наличие трех активных портов для подключения датчиков.<br /><br />
         <strong>Технические характеристики</strong><br />
         ЖК-монитор с диагональю 15,6”<br />   
         Вес с батареей 6,5 кг<br />   
         3 активных порта для подключения датчиков - уникально для портативных <br />   аппаратов!<br />   
         автооптимизация параметров изображения нажатием одной кнопки<br />   
         проспективная и ретроспективная запись видеоклипов<br />   
         диапазон рабочих частот 1-17 МГц<br />   
         модуль ЭКГ в базе<br />                                                     
      </p>
   );
}

export default e3;