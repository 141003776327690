const endoclean2000 = () => {
   return (
      <p>
         Предназначена для мытья и дезинфекции двух гибких эндоскопов. Оснащена мощной 3-канальной форсункой впрыска, позволяющей мыть два эндоскопа одновременно быстро и эффективно,
предотвращая смешивание с дезинфектантом и удаляя излишки влаги, оставшейся в эндоскопе, путем осушения осадка.
Внутренние отверстия обрабатываются воздушным душем, что было применено первый раз в Южной Корее.
         <br /><br />                         
         <strong>Технические характеристики</strong>
         <br /><br />
         - емкость ванны для мытья эндоскопов: 18 литров<br />
- вращающаяся на 360 градусов 3-х канальная струйная насадка обеспечивает профессиональную мойку эндоскопов<br />
- автоматическое открытие двери<br />
- функция подогрева дезинфектанта<br />
- LCD экран<br />
- система Bluetooth<br />
- гибкое время настройки для каждого цикла<br />
- мощный воздушный пистолет<br />
- воздушный душ<br />
- функция промывки дезинфекционного бака<br />
- возможность использования любого типа дезинфектанта для всех видов эндоскопов<br />
- поддержание концентрации дезинфектантов в течении всего времени цикла благодаря сниженному индексу разбавляемости стерилизующих средств<br />
- функция, предотвращающая утечку стерилизующих средств<br />
- легкое определение уровня стерилизующих средств<br />
- автоматический впрыск спирта во внутренние каналы<br />
- фильтр предварительной очистки воды<br />
- внутренний резервуар выполнен из материала стойкого к химическим веществам, дополнительное преимущество – установка эндоскопов не требует специальных инструментов и фиксаторов<br />
- встроенный принтер - наличие встроенных колес для перемещения - четыре вида автоматических режимов: A, B, C, D - возможность изменения, хранения настроек времени и осуществления обработки в 4 режимах A, B, C, D<br />
        <br />                                 
      </p>
   );
}

export default endoclean2000;