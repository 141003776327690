const news3 = () => {
    return (
        <p>
            С 17.05.2023 по 19.05.2023 в Алматы в КЦДС "Атакент" состоялась 28-я КАЗАХСТАНСКАЯ МЕЖДУНАРОДНАЯ ВЫСТАВКА "ЗДРАВООХРАНЕНИЕ".
            Где наша компания ТОО "Медруг" представит свои топовые модели Ультразвуковых систем и Видеоэндоскопического оборудования производства SonoScape Medical Corp.
                            
            <br /><br />
            <h3>ОСНОВНЫЕ ФАКТЫ ВЫСТАВКИ</h3>
            <br />
            Казахстанская Международная выставка «Здравоохранение» - KIHE является самой крупной демонстрационной площадкой достижений в области научной и практической медицины и фармацевтики в Центральной Азии и ежегодно объединяет врачей различных специальностей, фармацевтов, представителей власти и бизнеса из разных стран.
            <br />
            Ежегодно в выставке принимают участие производители и поставщики медицинского оборудования, аппаратов, расходных материалов для клиник и лабораторий; медицинских изделий различного назначения; оборудования для производства, упаковки и маркировки фармацевтических препаратов и др.
            <br />
            Параллельно с выставкой проходят международные форумы, конференции, круглые столы и мастер классы с участием представителей государственных органов, бизнесменов, руководителей медицинских учреждений и фармацевтических предприятий.
            <br />
            Экспонентами 2023 года выступили 309 компаний из 17 стран, таких как Беларусь, Великобритания, Венгрия, Германия, Египет, Италия, Иордания, Казахстан, Китай, Латвия, Пакистан, Россия, США, Франция, Чешская Республика, Узбекистан, Южная Корея. Дебютантами этого года стали 163 компании
            <br />
            45% стендов принадлежат казахстанским компаниям, демонстрируя весьма яркий и впечатляющий рост в развитии медицинской сферы Казахстана. Казахстан становится центром привлекательности для ведущих мировых производителей и поставщиков медицинского оборудования и инновационных решений, так 55% от общего состава участников – международные компании.
            <br />
            4007 уникальных посетителей из числа врачей различных специальностей, фармацевтов, представителей бизнеса отметили качественное наполнение экспозиции и количество представленных инновационных решений в области медицинской техники, лабораторного оборудования.
            <br />
            Проект KIHE проходит при официальной поддержке Министерства здравоохранения Республики Казахстан, Акимата Алматы, КГУ «Управление общественного здоровья Алматы», Ассоциации международных фармацевтических производителей в РК, Евразийской Медицинской Ассоциации, Ассоциации поддержки и развития фармацевтической деятельности РК.
        </p>
    );
}

export default news3;