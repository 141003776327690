const news4 = () => {
    return (
        <p>
            ТОО «МедРуг» приняли участие в 1-м Эндоскопическом форуме Центральной Азии, который состоялся 25-26 мая 2023 года в г. Астана.
            <br /><br />
                    
            На форуме были обсуждены следующие вопросы:<br />
            🔹Эндоскопическая диагностика воспалительных заболеваний кишечника<br />
            🔹Эндоскопическая диагностика предопухолевой и опухолевой патологии органов желудочно-кишечного тракта<br />
            🔹Эндоскопическая диагностика и методы лечения патологии органов гепатобилиарной зоны<br />
            🔹Оперативная эндоскопия<br />
            🔹Интервенционная бронхология.<br />
            <br />
            Кроме того, на Форуме были  представлены ведущие производители медицинского оборудования, в том числе SonoScape Medical Corp.
            <br />
            Одной из главных особенностей этой конференции является трансляция операций в режиме реального времени, которая позволит участникам присутствовать на операционном столе и получить уникальный опыт от ведущих экспертов в области эндоскопии.
            <br /><br />
            Организаторы: Национальный научный онкологический центр, ОО «Казахское Эндоскопическое Общество». Форум проходил при поддержке Министерства здравоохранения РК.
        </p>
    );
}

export default news4;