const hd500 = () => {
   return (
      <p>
         Видеоэндоскопическая система SonoScape HD-500 класса FULL HD 1080P применяется для обследования и эффективного лечения пациентов. Использование такого оборудования помогает получить изображение с камеры, введенной в организм пациента. Картинка четкая и понятная, может быть записана на носитель для дальнейшего использования.
         <br /><br />                       
         <strong>Видеопроцессор HD-500</strong>
         <br /><br />
         • Разрешение подключаемых видеоэндоскопов – 1 000 000 пикселей <br />
         • Система поддерживает два способа ввода информации о пациенте: предварительный ввод данных пациента и ввод данных пациента во время обследования.<br /> 
         • Режим мультиспектральной визуализации VIST - экспертный режим, работающий с использованием определенных длин волн падающего и отраженного света, позволяет проводить выявление онкологических новообразований на ранней стадии с высоким уровнем достоверности<br />  
         • Программирование 4-х кнопок на рукоятке эндоскопа<br /> 
         • Цифровое увеличение изображения - 3 уровня, до 4-х крат<br /> 
         • Режим полноэкранного изображения<br /> 
         • Режим CHb - цветовое картирование концентрации гемоглобина<br /> 
         • Режимы структурной детализации изображения - Edge, Structure A, Structure B<br /> 
         • Режим автоматической регулировки яркости изображения (IRIS)<br /> 
         • Ручной выбор алгоритма автоматической регулировки яркости изображения Peak, Ave, Peak+Ave<br /> 
         • Ручная настройка цветности изображения<br /> 
         • Ручная настройка цветовой насыщенности изображения – 3 уровня<br /> 
         • Сохранение до 10 комбинаций настроек параметров исследования для использования в различных областях и условиях различными специалистами<br /> 
         • Режим стоп-кадра, запускаемый с рукоятки эндоскопа или педали<br /> 
         • Автоматический баланс белого 
         <br /><br />
         <strong>Интегрированная цифровая рабочая станция:</strong>
         <br /><br />
         • Автоматический баланс белого<br />                        
         • Встроенный жесткий диск 500 Гб для хранения изображений, видео, отчетов, данных пациентов<br />   
         • USB порты для подключения внешних накопителей и принтеров<br />   
         • Клавиатура с трекболом для ввода данных пациента и управления функциями системы<br />   
         • Ввод и сохранение данных о пациентах, поиск сохраненных данных<br />   
         • Сохранение изображений/видео на жесткий диск с выводом на экран под основным изображением в режиме предпросмотра 
         <br /><br />
         <strong>Ксеноновый источник света HDL-500</strong>
         <br /><br />
         • Основная лампа – ксеноновая, 5900К, 300 Вт, срок службы до 500 часов <br />
         • Запасная лампа - галогеновая <br />
         • Электрическая помпа, 3 уровня мощности<br />
         • Водяная струя - поток воды, подаваемый по дополнительному каналу эндоскопа (также данный канал используется для ввода лекарственных средств), омывает проблемные участки слизистой и существенно упрощает проведение обследования (требует подключения дополнительной помпы)<br />
         • Автоматическая и ручная регулировки силы света<br />
         • Функция кратковременного аварийного увеличения яркости свечения для определения местоположения эндоскопа в трудных или экстренных случаях<br />
         • Индикатор отработанного времени на лицевой панели осветителя 
         <br /><br />
         <strong>Цифровой медицинский монитор высокой четкости Full HD</strong>
         <br /><br />
         • Диагональ - 24 дюйма<br />
         • Формат – 16:9<br />
         • Эндоскопическая тележка HDT-500<br />
         • Регулируемый держатель для монитора<br />
         • 4 полки (2 - регулируемые по высоте)<br />
         • 2 кронштейна для эндоскопов
         <br /><br />                        
         <strong>Возможные для подключения видеоэндоскопы:</strong>
         <br /><br />                        
         • Видеогастроскоп EG-500<br />
         • Видеоколоноскоп EC-500T<br />
         • Видеобронхоскоп EB-500<br /><br />                        
         <strong>Дополнительные принадлежности для HD-500 Full HD</strong><br /><br />
         Ножной переключатель для управления функцией стоп-кадра                                              
      </p>
   );
}

export default hd500;