const detro_enzym = () => {
    return (
        <>
            Химический состав (ингредиенты):<br /> 
            Ферменты - липаза, амилаза, протеаза; дидецилдиметиламмония хлорид, нонилфенол этоксилат, монопропиленгликоль, моноэтиленгликоль<br /> 
            Области применения:<br /> 
            Используется для очистки общего хирургических инструментов, гибкие и жесткие эндоскопы, <br /> анестезиологического оборудования и инструментов и т. д.<br /> 
            Концентрат, объем - 5 л.<br /> 
        </>
    );
}

export default detro_enzym;