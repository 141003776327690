const probe2p1 = () => {
    return (
        <p>
            <br /><b>Частота:</b> 1.9-6.0 / 1.0-5.4 МГц<br /><b>Количество элементов:</b> 64 элемента<br />
            <b>Клиническое применение:</b>  кардиология, транскраниальные исследования, абдоминальные исследования.
            <br /><br />            
        </p>
    );
}

export default probe2p1;