const p25 = () => {
   return (
      <p>
                                    
         Основываясь на многолетнем техническом опыте в области ультразвука, P25 наделен передовой технологией SonoScape для получения изображений высокой четкости, чтобы эффективно повысить диагностическую достоверность.<br />
         Утончённые функции и богатые конфигурации датчиков обеспечивают комплексное решение для управления всеми аспектами повседневной клинической практики с легкостью и уверенностью.<br /><br />
         <strong>Технические характеристики</strong><br />
         • 21,5-дюймовый LED монитор высокой четкости.<br />
         • 13,3-дюймовая сенсорная панель с быстрым временем отклика.<br />
         • Регулируемая по высоте и горизонтально вращающаяся панель управления<br />
         • Широкополосная платформа, нацеленная на получение превосходного клинического изображения.<br />
         • Динамический поток для обнаружения кровотока низкоскоростных сосудов даёт более высокую чувствительность.<br />
         • Доступно множество монокристаллических датчиков.<br />
         • Технологии: Панорамное сканирование, C-xlasto, Vis-
         Needle, TDI, S-Live Silhouette.<br />
         • Встроенный аккумулятор большой ёмкости.<br />
         • DICOM, Wi-Fi, Bluetooth.<br />
      </p>
   );
}

export default p25;