const acecide = () => {
    return (
        <>
            Химический состав (ингредиенты):<br /> 
            Содержит перкарбонат натрия моногидрат 50,5%, тетраацетилэтилендиамин 34,5%, антикоррозийные добавки и другие функциональные компоненты. Действующими веществами в рабочем растворе препарата является надуксусная кислота и активный кислород.<br /> 
            Водородный показатель рН 2% раствора составляет 7,0 - 9,0 ед. <br /> 
                Срок годности средства в невскрытой упаковке производителя составляет 5 лет при соблюдении условий хранения. <br /> 
                Срок годности рабочих растворов - 24 часа при условии их хранения в закрытых непрозрачных емкостях. <br /> 
            Области применения:<br /> 
                - для дезинфекции высокого уровня (ДВУ) эндоскопов; <br /> 
                - для стерилизации медицинских изделий (включая хирургические и стоматологические инструменты, жесткие и гибкие эндоскопы и инструменты к ним).<br /> 
            Концентрат, объем - пластиковая банка 1,5 кг.<br /> 

        </>
    );
}

export default acecide;