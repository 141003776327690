const mediola_endo = () => {
   return (
      <p>
         <strong>Параметры:</strong>
         <br /><br />
         • Импульсный Nd: YAG - 1064 нм | 38...60 Вт<br />  
         • Импульсный Nd: YAG - 1320 нм | 22...36 Вт<br />  
         • Частота импульсов - 1064: 5-100 Гц; 1320: 5-60 Гц<br />  
         • Система охлаждения - автономная, жидкостная<br />  
         • Мощность - 15 / 30 Вт<br />  
         • Электропитание - 230 В, 50 Гц<br />  
         • Потребляемая мощность - не более 3000 Вт<br />   
         <br /><br />
         <strong>Управление:</strong>
         <br /><br />
         • Сенсорный графический дисплей с меню на русском языке<br />
         • Функции предварительной установки параметров излучения с возможностью переключения между ними в ходе операции<br />
         • Изменение яркости пятна пилотного луча<br />
         • Сохранение параметров при выключении аппарата<br />
         • Совместимость с современным эндоскопическим оборудованием<br />
         <br /><br />
         <strong>Безопасность:</strong>
         <br /><br />
         • Функция контроля исправности световодного инструмента<br />  
         • Блокировка подачи излучения при неустановленном инструменте<br />  
         • Функция самотестирования системы<br />  
         <br /><br />
         <strong>Аппарат выбора для:</strong>
         <br /><br /> 
         • Эндоскопических вмешательств у пациентов с металлическими имплантатами (электрокардиостимуляторы, эндопротезы и др.)<br /> 
         • Лечения ворсинчатых опухолей ректосигмоидного отдела<br /> 
         • Лазерный гемостаз в просвете трахеи и главных бронхов <br /> 
         <br /><br />
         <strong>Область применения:</strong>
         <br /><br />  
         • Эндоскопия верхних отделов ЖКТ<br /> 
         • Эндоскопия нижних отделов ЖКТ<br /> 
         • Эндоскопия трахеобронхиального дерева<br />                            
      </p>
   );
}

export default mediola_endo;