const probe5p2 = () => {
    return (
    <p>
        <br /><b>Частота:</b> 2.0-9.0 МГц<br /><b>Количество элементов:</b> 80 элемента<br />
        <b>Клиническое применение:</b> кардиология, педиатрия.
        <br /><br />            
    </p>
    );
}

export default probe5p2;