const news1 = () => {
    return (
        <p>
            <h3>Приглашаем Вас посетить наш стенд на 17-й МЕЖДУНАРОДНОЙ ВЫСТАВКЕ ПО ЗДРАВООХРАНЕНИЮ "ASTANA ZDOROVIE 2021" </h3>
            <br />
            Выставка AstanaZdorovie - главная площадка в г.Нур-Султан для демонстрации медицинской техники и оборудования, расходных материалов, лекарственных препаратов, товаров медицинского назначения и медицинских услуг. Для многих компаний участие в выставке AstanaZdorovie открывает большие возможности для начала сотрудничества с предприятиями Казахстана. Ежегодно тысячи специалистов отрасли, в рамках выставки, знакомятся с новыми технологиями и разработками, находят деловых партнеров и активно обмениваются опытом.
        </p>
    );
}

export default news1;