const s8exp = () => {
   return (
      <p>
         Портативный ультразвуковой сканер SonoScape S8Exp на платформе VISTA представляет собой дальнейшее развитие популярной серии S8, снискавшей признание многих специалистов в области медицины и медицинских технологий. Перед вами экспертная модель, созданная ведущим мировым производителем, и она заслуживает особого внимания.
         <br /><br />
         Переносной УЗИ аппарат SonoScape S8Exp заметно облегчает работу вне зависимости от места проведения исследования, будь то кабинет медицинского центра, автомобиль неотложной помощи или обычная квартира. Множество современных технологических опций наряду с бюджетной ценой уже позволили тысячам врачей воспользоваться этим сканером на благо миллионов пациентов.
         <br /><br />                         
         <strong>Технические характеристики</strong>
         <br /><br />
         • Большой ЖК-монитор с диагональю 15”<br />
         • 2 активных порта для подключения датчиков<br />                      
         • Встроенная батарея, от 90 минут работы в режиме сканирования<br />
         • Вес 9 кг.
         <br /><br />
         <strong>Режимы сканирования:</strong>
         <br /><br />
         • В, М, В/М, В/В, 4В, Тканевая гармоника<br />
         • Изменение масштаба изображения в режимах реального времени и стоп-кадра<br />
         • Цветной, энергетический, направленный энергетический, импульсно-волновой допплер, HPRF (регистрация высоких скоростей кровотока), постоянно-волновой, тканевой допплер<br />
         • Дуплексный, триплексный режимы<br />
         • Трапецеидальное сканирование на линейных датчиках<br />
         • FreeHand 3D – режим поверхностной трехмерной реконструкции<br />
         • Технология подавления спекл-шума MicroScan<br />
         • Compound Imaging (режим раскачки УЗ-луча)<br />
         • 4D – режим трехмерной реконструкции в реальном времени<br />
         • Анатомический М-режим, Цветной М-режим, Панорамное сканирование<br />
         • Режим соноэластографии с количественной оценкой<br />
         <br /><br />
         <strong>Цифровая рабочая станция:</strong>
         <br /><br />
         • Жесткий диск 500 Гб, USB 2.0, Ethernet, DICOM 3.0<br />
         • Расчеты для акушерства, гинекологии, ангиологии, урологии, ортопедии, поверхностных, абдоминальных органов, кардиологии, возможность оценки кардио-сосудистой системы, головного мозга плода<br />
         • Составление и импорт отчетов с возможностью добавления изображений<br />
         • Ведение БД пациентов, возможность сохранения и поиска изображений, клипов, 3D-образов по различным полям базы данных                                                        
      </p>
   );
}

export default s8exp;