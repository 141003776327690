import { useState, useEffect, useMemo } from 'react';
import { FaWhatsappSquare, FaFacebookSquare, FaInstagramSquare, FaYoutubeSquare } from "react-icons/fa"
import { SocialsIcon } from "./SocialsIcon";

const SocialsWidget = () => {
  const [isPageEnd, setIsPageEnd] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight) {
        setIsPageEnd(true);
      } else {
        setIsPageEnd(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setIsPageEnd]);

  const style = useMemo(
    () => ({
      position: 'fixed',
      bottom: 20,
      right: 20,
      width: isPageEnd ? 120 : 200,
      display: 'flex',
      flexWrap: 'wrap',
      zIndex: 10,
      transition: '.3s',
    }), [isPageEnd]);

  return (
    <div style={style}>
      <SocialsIcon 
        href="https://api.whatsapp.com/send/?phone=77751929911&text&app_absent=0"
        icon={<FaWhatsappSquare />}
        isPageEnd={isPageEnd}
      />
      <SocialsIcon 
        href="https://www.facebook.com/medrug.kz?ref=bookmarks&_rdc=1&_rdr"
        icon={<FaFacebookSquare />}
        isPageEnd={isPageEnd}
      />
      <SocialsIcon 
        href="https://www.instagram.com/medrug.kz/"
        icon={<FaInstagramSquare />}
        isPageEnd={isPageEnd}
      />
      <SocialsIcon 
        href="https://www.youtube.com/channel/UC_WHReOoJmccm5-FId5lwWw/featured"
        icon={<FaYoutubeSquare />}
        isPageEnd={isPageEnd}
      />
    </div>
  );
}

export {SocialsWidget}