const plx118f = () => {
  return (
    <p>
      <strong>Технические характеристики</strong><br />
      •	Максимальная выходная электрическая мощность: 5 кВт
      •	Частота инвертора: ≥110 кГц<br /><br />
      <strong>Функциональные характеристики и клиническое применение:</strong><br />
      1. Новейший динамический плоскопанельный детектор, большое поле зрения, высокое разрешение, широкий динамический диапазон, низкий уровень шума, отсутствие искажений, компактный и легкий;<br />
      2. Эффективная зона визуализации 9 дюймов × 9 дюймов может удовлетворить потребности при различных клинических применениях;<br />
      3. Программное обеспечение для обработки изображений использует уникальную платформу быстрой динамической обработки и отображения изображений на базе графического процессора (RCDPS), технологии обработки изображений с разными разрешениями, чтобы удовлетворить разнообразные потребности клиентов;<br />
      4. Дополнительный черно-белый ЖК-монитор с более высоким разрешением и превосходным качеством изображения;<br />
      5.  Подключение к динамическому плоскопанельному детектору со скоростью в 1 Гбит/с; интерфейс международного стандарта Dicom 3.0, удобный для подключения к системе PACS для передачи данных и печати;<br />
      6. Высокочастотный высоковольтный генератор собственной разработки, реализующий высокочастотную импульсную рентгеноскопию и снижение дозы, лучший в Китае;<br />
      7. Эргономичный интерфейс управления изображением, интеллектуальный контроль дозы, снижение количества рутинной работы, подходит для хирургии;<br />
      8. Может быть предварительно настроен для различных типов тела пациентов и разных частей тела, прост в эксплуатации;<br />
      9.	Функция автоматического отслеживания яркости;<br />
      10. Благодаря многоэтапной автоматической защите и функции отображения кода неисправности техническое обслуживание становится более удобным;<br />
      11. Проведены независимые исследования и разработки каркаса C-дуги, для ее легкого и удобного применения в хирургии;<br />
      12. Благодаря отображению дозы DAP вы можете непосредственно наблюдать за дозой в процессе использования аппарата, чтобы защитить здоровье врачей и пациентов.
    </p>
  );
}
   
export default plx118f;