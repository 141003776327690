const eb500 = () => {
   return (
      <p>
         <br /><br />                       
         <strong>Видеобронхоскоп EB-500</strong>
         <br /><br /> 
         • Длина рабочая 600 мм.<br /> 
         • Диаметр 5,3 мм.<br /> 
         • Рабочий канал 2 мм.<br /> 
         • Угол обзора 120°<br /> 
         <br />                                        
      </p> 
   );
}

export default eb500;