const ec430t = () => {
   return (
      <p>
         <br /><br />                       
         <strong>Видеоколоноскоп EC-430T / EC-430L/T</strong>
         <br /><br /> 
         • Длина 1 950 мм.<br /> 
         • Длина рабочая 1 700 мм.<br /> 
         • Диаметр 12,5 / 12,9 мм.<br /> 
         • Рабочий канал 3,8 / 4,2 мм.<br /> 
         • Угол обзора 140°<br /> 
         • Двухканальный (есть дополнительный канал для растворов/жидкостей)
         <br />                                        
      </p> 
   );
}

export default ec430t;