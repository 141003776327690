const s2 = () => {
   return (
      <p>
         Портативный УЗИ сканер S2 от всемирно известной компании SonoScape является классическим бюджетным вариантом переносной мобильной системы. Более чем доступная цена компенсируется несколько сниженным функционалом, уступающим более современным и технологически продвинутым моделям.
         <br /><br />
         Тем не менее, переносной УЗИ аппарат SonoScape S2 остается популярным и применяется в сотнях медицинских учреждений. Одной из причин является неизменно высокое качество официального производителя SonoScape, другой – наличие функции эластографии, которая ранее входила только в комплектацию стационарных УЗИ устройств высокого класса.
         <br /><br />                         
         <strong>Технические характеристики</strong>
         <br /><br />
         • Большой ЖК-монитор с диагональю 15”<br />
         • 2 активных порта для подключения датчиков<br />
         <br /><br />
         <strong>Режимы сканирования:</strong>
         <br /><br />
         • В, М, В/М, В/В, 4В, Тканевая гармоника<br />
         • Изменение масштаба изображения в режимах реального времени и стоп-кадра<br />
         • Цветной, энергетический, направленный энергетический, импульсно-волновой допплер, постоянно-волновой, тканевый допплер<br />
         • Дуплексный, триплексный режимы<br />
         • Трапецеидальное сканирование на линейных датчиках<br />
         • Технология подавления спекл-шума MicroScan<br />
         • FreeHand 3D – режим поверхностной трехмерной реконструкции
         <br /><br />
         <strong>Цифровая рабочая станция:</strong>
         <br /><br />
         • Жесткий диск 500 Гб, USB 2.0, Ethernet<br />
         • Расчеты для акушерства, гинекологии, ангиологии, урологии, ортопедии<br />
         • Составление и импорт отчетов с возможностью добавления изображений<br />
         • Ведение БД пациентов, возможность сохранения и поиска изображений, клипов, 3D-образов по различным полям базы данных                                                        
      </p>
   );
}

export default s2;