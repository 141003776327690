const mpteemini = () => {
    return (
        <p>
            <br /><b>Частота:</b> 4.0-13.0 МГц<br /><b>Угол обзора:</b> 90°
            <br /><b>Толщина:</b> 7 мм<br /><b>Длина:</b> 70 мм<br />
            <b>Клиническое применение:</b> чреспищеводные кардиологические исследования, педиатрия
            <br /><br />            
        </p>
    );
}

export default mpteemini;