const hd350 = () => {
   return (
      <p>
         Представляет собой экспертный диагностический комплекс, который позволяет получать изображение высокой четкости (в формате HD). В сочетании с превосходным освещением способствует точному обнаружению пораженных областей, значительно улучшает видимость и оценку мельчайших очагов поражения. Служит для широкого спектра эндоскопических исследований, с возможностью подробного документирования данных. Обладает множеством инновационных функций, помогающих значительно упростить и облегчить работу врача.
         <br /><br />                       
         <strong>Система видеоэндоскопическая HD-350 с принадлежностями</strong>
         <br /><br />
         • Централизованная управляющая станция<br />
         • Устройство сконфигурировано с модулем DICOM, обеспечивает возможность загрузить информацию о пациенте из сервера DICOM<br />  
         • Возможность ввода имени, пола, возраста, даты рождения и идентификационный номер пациента, а также имя доктора в поле информации о пациенте во время обследования.<br />  
         • Настройка 20 групп пользователей<br />  
         • Система поддерживает два способа ввода информации о пациенте: предварительный ввод данных пациента и ввод данных пациента во время обследования.
         <br /><br />
         <strong>Видеопроцессор HD-350</strong>
         <br /><br />
         • Автоматический баланс белого<br />
         • Автоматическая регулировка усиления (вкыл/выкл)<br />
         • Контроль яркости и усиление контрастности<br />
         • Подчеркивание границ изображения (увеличивает резкость изображения, делает его более детализированным)<br />
         • Ручная настройка цветности изображения (позволяет получить оптимальную цветопередачу)<br />
         • Уровень улучшение цвета CHb 1,2,3.<br />
         • Фотометрия области (позволяет оптимизировать подавление бликов в зависимости от условий исследования)<br />
         • Вес: не более 11 кг
         <br /><br />
         <strong>Светодиодный источник света HDL-35E</strong>
         <br /><br />
         • 1 LED лампа<br />
         • Индикатор отработанного времени на лицевой панели осветителя<br />
         • Водяная струя - поток воды, подаваемый по дополнительному каналу эндоскопа (также данный канал используется для ввода лекарственных средств), омывает проблемные участки слизистой и существенно упрощает проведение обследования (требует подключения дополнительной помпы)<br />
         • Ручная регулировка яркости и цветности изображения 
         <br /><br />
         <strong>Цифровой медицинский монитор высокой четкости</strong>
         <br /><br />
         • Максимальное разрешение экрана 1280Х1024<br />
         • Формат изображения 16:9<br />
         • Размеры в мм (ш x в x г): не более 570Х1385Х60 
         <br /><br />                        
         <strong>Возможные для подключения видеоэндоскопы:</strong>
         <br /><br />
         • Видеогастроскоп EG-430<br />
         • Видеоколоноскоп EC-430T<br />    
         • Видеобронхоскоп EB-500<br />                                               
      </p>
   );
}

export default hd350;