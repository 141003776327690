const unilightg = () => {
   return (
      <p>      
         Оптический наконечник в игле с функцией выдвижения торца, с каналом для отвода пара и аспирации/ирригации жидкостей. Применяется для внутритканевых и внутриполостных манипуляций со сложным доступом.              
         <br /><br /> 
         <strong>Длина:</strong> 40мм / 80мм
         <br /><br />  
         <strong>Область применения:</strong>
         <br />
         Флебология<br />  
         Проктология<br />            
      </p>
   );
}

export default unilightg;