const probec353 = () => {
    return (
        <p>
            <br /><b>Апертура:</b> 50/55 мм<br /><b>Частота:</b> 2.0-6.8 МГц<br /><b>Количество элементов:</b> 128 элементов<br />
            <b>Клиническое применение:</b> абдоминальные исследования, акушерство, гинекология.
            <br /><br />            
        </p>
    );
}

export default probec353;