const v2000 = () => {
   return (
      <p>
         Новая видеосистема высокой четкости совместима с гибкими, жесткими и оптоволоконными эндоскопами, поэтому охватывает широкий спектр потребностей в ветеринарии. При покупке видеоэндоскопической системы стартовый набор эндоскопических инструментов Wilson Instruments в подарок! 
         <br /><br />                       
         <strong>Гибкие эндоскопы V-серии</strong>
         <br /><br />
         V-10L (для крупных животных) – длина вводимой части 3300 мм, диаметр вводимой части 12,5 мм, рабочий канал 3,8 мм, угол обзора 145°, глубина резкости 2-100 мм, углы изгиба изгибаемой части 200/180/160/160, дополнительный канал подачи жидкости<br /><br />

         V-10M (для средних животных) – длина вводимой части 1500 мм, диаметр вводимой части 9,0 мм, рабочий канал 2,8 мм, угол обзора 145°, глубина резкости 2-100 мм, углы изгиба изгибаемой части 210/90/120/120, дополнительный канал подачи жидкости<br /><br />

         V-10S (для мелких животных) – длина вводимой части 1100 мм, диаметр вводимой части 5,8 мм, рабочий канал 2,2 мм, угол обзора 145°, глубина резкости 2-100 мм, углы изгиба изгибаемой части 210/100/120/120
         <br /><br />
                                                      
      </p>
   );
}

export default v2000;