import { useMemo } from 'react';
import { useResize } from '../components/ResizeProvider';
import { PanelLink } from "./PanelLink";
import {
  ultrasoundImg,
  probesImg,
  endoscopyImg,
  instrumentsImg,
  helicobacterImg,
  veterinaryImg,
  laserImg,
  radioImg
} from '../img/home/links/images';

const LinkBlock = () => {
  const isMobile = useResize();

  const style = useMemo(
    () => ({
      display:'flex',
      justifyContent:'space-evenly',
      flexWrap: 'wrap',
      backgroundColor: 'whitesmoke',
      width: isMobile ? '100%' : '1200px',
      marginLeft: 'auto',
      marginRight: 'auto'
    }), [isMobile]);

  return (
    <div style={style} >
      <PanelLink name={'УЛЬТРАЗВУКОВЫЕ СИСТЕМЫ'} img={ultrasoundImg} link={'/catalog/ultrasound'} />
      <PanelLink name={'УЛЬТРАЗВУКОВЫЕ ДАТЧИКИ'} img={probesImg} link={'/catalog/probes'} />
      <PanelLink name={'ЭНДОСКОПИЧЕСКИЕ СИСТЕМЫ'} img={endoscopyImg} link={'/catalog/endoscopy'} />
      <PanelLink name={'ЭНДОСКОПИЧЕСКИЕ ИНСТРУМЕНТЫ'} img={instrumentsImg} link={'/catalog/instruments'} />
      <PanelLink name={'СИСТЕМЫ ДИАГНОСТИКИ HELICOBACTER PYLORY'} img={helicobacterImg} link={'/catalog/other'} />
      <PanelLink name={'ВЕТЕРИНАРНОЕ ОБОРУДОВАНИЕ'} img={veterinaryImg} link={'/catalog/veterinary'} />
      <PanelLink name={'РЕНТГЕНОВСКОЕ ОБОРУДОВАНИЕ'} img={radioImg} link={'/catalog/radiology'} />
      <PanelLink name={'ЛАЗЕРНОЕ ОБОРУДОВАНИЕ'} img={laserImg} link={'/catalog/lasers'} />
    </div>
  );
}

export {LinkBlock}