export const news5 = () => {
    return (
        <p>
            5 октября 2023 года наша компания приняла участие VIII Центрально-Азиатской гастроэнтерологической неделе.<br />

            Научная программа Конгресса включает пленарные заседания и научные симпозиумы по следующим темам:

            <br /><br />Хронический вирусный гепатит 
            <br />Неалкогольная и алкогольная жировая болезнь печени;
            <br />Аутоиммунные заболевания печени;
            <br />Лекарственно-индуцированные повреждения печени;
            <br />Цирроз печени и его осложнения;
            <br />Функциональные заболевания органов пищеварения;
            <br />Заболевания верхнего отдела желудочного кишечного тракта;
            <br />Воспалительные заболевания кишечника;
            <br />Инфекционная гастроэнтерология;
            <br />Хирургическая гастроэнтерология;
            <br />Канцерогенез и канцеропревенция в гастроэнтерологии и гепатологии
        </p>
    );
}

export default news5;