const s50elite = () => {
   return (
      <p>
         Учитывая ключевые потребности и задачи врачей, S50 ELITE превосходят ваши ожидания в отношении ультразвуковой системы в этом сегменте, особенно для акушерства и гинекологии. Эта новейшая ультразвуковая система предоставляет врачам выдающееся сочетание точности, повышенной производительности и продуманного рабочего процесса. Мы верим и верим в то, что предоставляем врачам возможность быстрой и надежной диагностики, и S50 ELITE является отвечает предъявляемым требованиям.<br /><br />
         <strong>Технические характеристики</strong><br />
         •	Объемный датчик премиум-класса VC2-9 обеспечивает превосходное качество изображения как при 2D, так и при 3D/4D сканировании со сверхширокой полосой пропускания.<br />
         •	Автоматизированные инструменты на основе искусственного интеллекта, такие как S-Fetus и Auto OB, помогают повысить эффективность рабочего процесса.<br />
         •	Высококачественные внутриполостные датчики 6V3 и 6V7 могут достигать угла обзора 194° (210° с WideScan).<br />
         •	Объемный внутриполостной датчик VE9-5 также имеет широкий трехмерный угол обзора 120°.
         •	Передовые методы рендеринга, такие как Color 3D, S-Live/S-Live Silhouette, обеспечивают реалистичную визуализацию анатомии, предоставляя более подробную и полезную информацию.<br />
         •	4D HyCoSy (гистеросальпингоконтрастная сонография) облегчает оценку состояния фаллопиевой трубы, интуитивно понятна и надежна.<br />
         •	Объемный датчик премиум-класса VC2-9 обеспечивает превосходное качество изображения как при 2D, так и при 3D/4D сканировании со сверхширокой полосой пропускания.<br />
         •	Автоматизированные инструменты на основе искусственного интеллекта, такие как S-Fetus и Auto OB, помогают повысить эффективность рабочего процесса.<br />
         •	Высококачественные внутриполостные датчики 6V3 и 6V7 могут достигать угла обзора 194° (210° с WideScan).<br />
         •	Объемный внутриполостной датчик VE9-5 также имеет широкий трехмерный угол обзора 120°.<br />
         •	Передовые методы рендеринга, такие как Color 3D, S-Live/S-Live Silhouette, обеспечивают реалистичную визуализацию анатомии, предоставляя более подробную и полезную информацию.<br />
         •	4D HyCoSy (гистеросальпингоконтрастная сонография) облегчает оценку состояния фаллопиевой трубы, интуитивно понятна и надежна.<br />                          
      </p>
   );
}

export default s50elite;
