const plx112b1 = () => {
    return (
      <p>
        <strong>Технические характеристики</strong><br />
        •	Высоковольтный инверторный источник питания;<br />
        •	Максимальная выходная мощность: 5 кВт;<br />
        •	Частота инвертора: ≥40 кГц;<br /><br />
        <strong>Функциональные характеристики и клиническое применение:</strong><br />
        1. Высококачественный комбинированный рентгеновский генератор высокой частоты и высокого давления, значительно снижающий количество рентгеновского облучения;<br />
        2. С флюороскопической функцией автоматического отслеживания кВ и мА, яркость и четкость изображения настраиваются автоматически;<br />
        3. Доза излучения в режиме непрерывной импульсной флюороскопии в режиме реального времени невелика, а изображение более четкое, может удовлетворить потребности высокоточной, малоинвазивной хирургии высокой сложности, может эффективно защитить безопасность медицинского персонала и пациентов;<br />
        4. При помощи интерфейса с сенсорным ЖК-экраном работа становится более удобной;<br />
        5. Конструкция портативного контроллера более удобна в эксплуатации;<br />
        6. 9-дюймовый электронно-оптический преобразователь, стабильное и надежное качество, хорошая четкость изображения;<br />
        7. При помощи мегапиксельной цифровой камеры со сверхнизкой освещенностью, изображение становится более четким;<br />
        8. Стандартная рабочая станция с передовой технология обработки изображений делает изображение более четким, удобным для хирургии и диагностики, стандартный интерфейс Dicom, удобный для связи с информационной системой больницы;<br />
        9. Конструкция электрического вспомогательного опорного рычага более безопасна в использовании;<br />
        10. Новый дизайн стойки, компактный и стильный внешний вид;<br />
        11. Функции цифровой фотографии, работа с фотографиями более проста, цифровая обработка изображений более эффективна.
      </p>
    );
   }
   
   export default plx112b1;