const sololight = () => {
   return (
      <p>      
         Оптический наконечник, с кольцевым выходом излучения (технология SoloLight), предназначен для проведении внутрисосудистых (в том числе для эндовазальной лазерной коагуляции) и внутриполостных вмешательств.                 
         <br /><br /> 
         <strong>Длина:</strong> 150мм / 350мм / 600мм / 1500мм
         <br /><br />  
         <strong>Область применения:</strong>
         <br />  
         Флебология<br />  
         Проктология<br />            
      </p>
   );
}

export default sololight;