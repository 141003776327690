const probeve95 = () => {
    return (
    <p>
        <br /><b>Частота:</b> 3.6-9.6 МГц<br /><b>Количество элементов:</b> 128 элементов<br />
        <b>Клиническое применение:</b> акушерство, гинекология, урология.
        <br /><br />            
    </p>
    );
}

export default probeve95;