const insuflator = () => {
   return (
      <p>                           
         <br /><br />
         Использование СО2 вместо воздуха доказано приводит к уменьшению
         времени проведения обследования, значительно ускоряет время
         восстановления после процедуры и намного более комфортно для пациента
         во время процедур.
         <br /><br />                       
         <strong>Технические характеристики</strong><br /><br />

         Максимальное давление подачи: 0,9 МПа <br />
         Выходной поток:<br />
         2л/мин (± 0.3 л / мин)<br />
         3Л/мин (± 0.3 л / мин)<br />
         Размеры: 128 мм * 330 мм * 155 мм<br />
         Вес: 5 кг<br />
         Напряжение тока (AC): 100-240 В<br />
         Частота: 50 Гц<br />
         <br />                                        
      </p> 
   );
}

export default insuflator;