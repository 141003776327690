const endoclean1000 = () => {
   return (
      <p>
         Установка для мойки и дезинфекции гибких эндоскопов предназначена для мойки и дезинфекции одного гибкого эндоскопа.

         Оснащена 4 форсунками для впрыска, что обеспечивает качественную очистку эндоскопов. Предотвращает смешивание с дезинфектантом, удаляя излишки влаги, оставшейся в эндоскопе, путем осушения осадка.<br /> 

         Полный цикл обработки эндоскопа составляет 15 минут, русскоязычное ПО, существенно облегчающее работу специалистов с системой, универсальность обеспечена совместимостью с эндоскопами любого производителя, интегрированное тестирование эндоскопов на герметичность, основная комплектация включает собственную систему водоподготовки, независимые программы обработки, в том числе автоматизированный цикл химической самодезинфекции системы, исключающий риск повторной контаминации поверхности устройства.
         <br /><br />                         
         <strong>Технические характеристики</strong>
         <br /><br />
         • емкость ванны для мытья эндоскопов: 15 литров - вращающаяся на 360 градусов 3-х канальная струйная • • насадка обеспечивает профессиональную мойку эндоскопов
         • возможность установки времени цикла от 7 мин до 15 мин.
         • возможность использования любого типа дезинфектанта для всех видов эндоскопов
         • поддержание концентрации дезинфектантов в течении всего времени цикла благодаря сниженному индексу разбавляемости стерилизующих средств
         • функция, предотвращающая утечку стерилизующих средств
         • легкое определение уровня стерилизующих средств
         • автоматический впрыск спирта во внутренние каналы
         • фильтр предварительной очистки воды
         • внутренний резервуар выполнен из материала, стойкого к химическим веществам, дополнительное преимущество – установка эндоскопов не требует специальных инструментов и фиксаторов
         • встроенный принтер
         • наличие встроенных колес для перемещения
         • два вида автоматических режимов: A, B
         • возможность изменения, хранения настроек времени и осуществления обработки в 2 режимах A, B
        <br />                                 
      </p>
   );
}

export default endoclean1000;