const news2 = () => {
    return (
        <p>
            <h3>Уважаемые коллеги и партнеры!</h3>
            <br />
            С 18.05 по 20.05.2022 в Алматы в КЦДС "Атакент" состоится 27-я КАЗАХСТАНСКАЯ МЕЖДУНАРОДНАЯ ВЫСТАВКА "ЗДРАВООХРАНЕНИЕ".
            Где наша компания ТОО "Медруг" представит свои топовые модели Ультразвуковых систем и Видеоэндоскопического оборудования производства SonoScape Medical Corp. 
            На нашем стенде Вы также будете иметь возможность обсудить детали сотрудничества. Приглашаем Вас посетить наш стенд. Регистрация по промокоду, указанному в пригласительной.
            <br />
            Более 25-ти лет Казахстанская Международная выставка «Здравоохранение» - KIHE является самой крупной демонстрационной площадкой достижений в области научной и практической медицины и фармацевтики в Центральной Азии и ежегодно объединяет врачей различных специальностей, фармацевтов, представителей власти и бизнеса из разных стран.
        </p>
    );
}

export default news2;