const e2 = () => {
   return (
      <p>
         Е2 — это цветная доплеровская ультразвуковая система начального уровня, которая превосходит ваши ожидания благодаря своему компактному и модному внешнему виду. Он прекрасно справляется с работой в следующих областях: общей визуализации, акушерско-гинекологической, кардиологической и реанимации помощи в соответствии с вашими обычными потребностями сканирования, а его цветовой режим поможет вам более точно и эффективно диагностировать поражения.<br /><br />
         <strong>Технические характеристики</strong><br />
         • 15,6-дюймовый анти-мерцающий LED монитор с высоким разрешением.<br />
         • До 2 портов для датчиков.<br />
         • Клавиатура с подсветкой и интеллектуальная панель управления.<br />
         • Длительное время работы от аккумулятора до 90 мин.<br />
         • Wi-Fi, Bluetooth, DICOM, жесткий диск 500 ГБ.<br />
         • Удобный рюкзак-чемодан с колесами.<br />                                                     
      </p>
   );
}

export default e2;