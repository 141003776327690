const helicobacter = () => {
   return (
      <p>
         <br /><br />                       
         <strong>Тестер Helicobacter Pylori HUBT-20P</strong>
         <br /><br />
         Тестер Helicobacter pylori HUBT-20P поставляется в медицинские учреждения для выявления бактерии Helicobacter pylori (H. pylori). Исследуемый принимает внутрь капсулу с 14C-мочевиной. При наличии в желудке пациента бактерии H. pylori, 14C-мочевина  с помощью фермента мочевины преобразуется в 14CO2. Факт наличия инфекции H. pylori определяется забором пробы выдыхаемого пациентом воздуха и определением концентрации 14C в пробе.<br /><br />
         <strong>Достоинства прибора:</strong><br /><br />
         • Совершенно новый дизайн и конструкция, элегантный внешний вид, портативность. <br /> 
         • Оптимизированные электросхемы и новейшие компоненты, более надежные и эффективные.<br /> 
         • Более интуитивный и удобный пользовательский интерфейс.<br /> 
         • Удобство в эксплуатации за счет автоматического анализа, бескнопочное управление.<br />
         • Автоматическая диагностика неисправностей, автоматические фоновые вычисления, автоматическая калибровка.<br /> 
         • Автоматический вывод значений С и положительного/отрицательного результата теста.<br />
         • Автоматический термопринтер компактных размеров.<br /> 
         <br />                                        
      </p>
   );
}

export default helicobacter;