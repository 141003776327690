const p50elite = () => {
   return (
      <p>
         P50 ELITE, созданный для обеспечения максимальной производительности в линейке ELITE, будет вашим надежным партнером, помогающим в получении информативных клинических данных и их оценке в широкой области применения с интуитивно понятным управлением. Для того, чтобы обеспечить максимальное качество каждого проведенного исследования, мы стремимся к элегантности и комфорту, чтобы вы могли наслаждаться работой с P50 ELITE с безграничными возможностями.<br /><br />
         <strong>Технические характеристики</strong><br />
         •	Семейство высокопроизводительных датчиков, включая монокристаллисталлические конвексные, фазированные и линейные датчики композитного кристалла обеспечивают значительно большее разрешение и проникновение.<br />
         •	Усовершенствованный алгоритм визуализации кровотока Micro F и SR-Flow расширяет видимый диапазон потока.<br />
         •	Автоматизированные функции, например, Auto OB, Auto NT, AVC Follicle, Auto EF, Auto Bladder, Auto Face, Auto B/C, Auto IMT, улучшают эффективность сканирования.<br />
         •	Абдоминальное и поверхностное исследование CEUS расширяет возможности просмотра структуры ткани и васкуляризации поражения, особенно в печени и щитовидной железе.<br />
         •	S-Live/S-Live Silhouette, Color 3D, S-Fetus — профессиональная реализация функций для акушера-гинеколога.<br />

      </p>
   );
}

export default p50elite;
