const pld9600d = () => {
  return (
    <p>
      <strong>Функциональные характеристики:</strong><br /><br />
      Интеллектуальный подъем стола<br />
      •	Поверхность стола можно поднять на высоту от 55 до 135 см над уровнем пола.<br />
      •	Уникальная и практичная функция подъема стола, которая не только помогает при рентгенографии, но и необходима для пациентов с низкой подвижностью, а также для предотвращения  вторичных травм в случае пациентов, транспортировка которых осложнена.<br />
      •	Операторам может быть полезна регулировка высоты стола во время проведения процедур.<br /><br />
      Поворот колонны<br />
      •	Угол наклона трубки составляет от -45° до 45°, что позволяет удовлетворить потребности рентгенографии при различном клиническом применении, например, ось пяточной кости, ось надколенника, шейный отдел позвоночника, и т.д.<br /><br />
      Вращение стола <br />
      •	Стол можно поворачивать на ±90° по вертикали, что значительно расширяет возможности клинического применения. При этом он движется стабильно и плавно, что может продлить срок службы.<br /><br />
      Изменение фокусного расстояния<br />
      •	Фокусного расстояние, которое можно увеличить до 1,8 метра, может удовлетворить потребности для различных применений в радиологических отделениях.<br /><br />
      Свободное перемещениие колонны<br />
      •	Детектор можно перемещать более свободно, что исключает необходимость перемещения пациента.<br />
      •	Поверхность стола перемещается вбок, что также позволяет не перемещать пациента.<br />
    </p>
  );
 }
 
 export default pld9600d;