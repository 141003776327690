const m5exp = () => {
    return (
        <p>
         Является оптимальным решением для всех клинических ультразвуковых исследований.

Комбинация технологий оптимизации качества изображения, широкополосного цифрового формирования луча, динамической фокусировки, динамической апертуры в реальном режиме времени, цветного допплера и многое другое, делает этот сканер отличной цифровой портативной ультразвуковой системой и обеспечивает полное ультразвуковое диагностирование в области брюшных, кардиологических, акушерских, гинекологических, урологических исследованиях, а также  исследовании поверхностных тканей и прилегающих периферийных сосудов.
<br /><br />
         <strong>Технические характеристики</strong><br />
         15,6-дюймовый ЖК-монитор высокого разрешения с максимальным регулируемым угло
м 75 градусов;<br />
3 разъема датчиков;<br />
Хранение необработанных данных для автономного отчета и настройки параметров;<br />
Жесткий диск емкостью 1 TB и 3 порта USB;<br />
Интерфейсы: видео, S-Video, HDMI, DICOM3.0, принтер, Ethernet;<br />
Режим изображения: B, 2B/4B,М, АММ, изогнутый АММ, СМ, CDFI, PDI, DPDI, CW, PW, TDI,
3D/4D;<br />
Пакеты программного обеспечения для измерения и расчета: брюшная полость，акушер
ство и гинекология, сердце，сосудистая ，урология，малые органы；<br />
EFOV, Панорамный, ZoneElast, SCI, FCI, ZClear, PIHI, THI, TSI, 3D/4D, IMT, HR-Flow,
zMicroFlow;<br />
PW Авто-огибающая и расчет;<br />
Сердечно-сосудистые заболевания: CW, TDI, IMT, Auto EF;<br />
Акушерство и гинекология: измерение тазового дна, 3D/4D, ZLive, Smart OB, Auto NT, Auto
Follicle;<br />
Встроенная литиевая батарея  <br />     
        </p>
    );
}

export default m5exp;