const probec542 = () => {
    return (
        <p>
            <br /><b>Апертура:</b> 40 мм<br /><b>Частота:</b> 3.7-11.0 МГц<br /><b>Количество элементов:</b> 128 элементов<br />
            <b>Клиническое применение:</b> абдоминальные исследования, педиатрия.
            <br /><br />            
        </p>
    );
}

export default probec542;