const s20exp = () => {
   return (
      <p>
         Экспертный УЗИ сканер S20Exp стал настоящим технологическим прорывом в области видеоэндоскопических систем SonoScape. Обновленная базовая система VISTA повысила быстродействие и эффективность работы.
         <br /><br />
         Благодаря обновленной базовой системе VISTA данный УЗИ сканер от компании SonoScape стал приятным сюрпризом для специалистов по всему миру. Эта экспертная модель увенчала собой блестяще зарекомендовавшую себя «линейку» продукции S20, превзойдя ее по скорости, качеству и производительности работы.
         <br /><br />
         УЗИ аппарат SonoScape S20Exp станет по-настоящему выгодным приобретением при расширении или открытии вашего медицинского центра. Вас ожидает привлекательная цена в сочетании с эффективными опциями и экспертными технологиями от официального производителя.
         <br /><br />  
         <strong>Технические характеристики</strong>
         <br /><br />
         • Большой ЖК-монитор с диагональю 17”<br />
         • 4 активных порта для подключения датчиков,<br />
         • Сенсорная панель управления<br />
         • Встроенная батарея, до 120 минут работы в режиме сканирования (опция) 
         <br /><br />
         <strong>Режимы сканирования:</strong>
         <br /><br />
         • В, М, В/М, В/В, 4В, Тканевая гармоника, Режим улучшенной визуализации биопсийной иглы<br />
         • Изменение масштаба изображения в режимах реального времени и стоп-кадра<br />
         • Цветной, энергетический, направленный энергетический, импульсно-волновой допплер, HPRF (регистрация высоких скоростей кровотока), постоянно-волновой, тканевой допплер<br />
         • Дуплексный, триплексный режимы<br />
         • Compound Imaging (режим раскачки УЗ-луча)<br />
         • Трапецеидальное сканирование на линейных датчиках<br />
         • FreeHand 3D – режим поверхностной трехмерной реконструкции<br />
         • 4D – режим трехмерной реконструкции в реальном времени<br />
         • Анатомический М-режим, Цветной М-режим, Панорамное сканирование<br />
         • Режим СоноЭластографии с количественной оценкой (опция)<br />
         • Технология подавления спекл-шума MicroScan<br />
         • Режим работы с ультразвуковыми контрастными веществами
         <br /><br />
         <strong>Цифровая рабочая станция:</strong>
         <br /><br />
         • Жесткий диск 500 Гб, USB 2.0, DVD-RW, Ethernet, DICOM 3.0<br />
         • Расчеты для акушерства, гинекологии, ангиологии, урологии, ортопедии, поверхностных, абдоминальных органов, кардиологии, возможность оценки кардио-сосудистой системы, головного мозга плода, вывода кривых роста плода, автоматический анализ толщины комплекса интима-медиа<br />
         • Составление и импорт отчетов с возможностью добавления изображений<br />
         • Ведение БД пациентов, возможность сохранения и поиска изображений, клипов, 3D-образов по различным полям базы данных                                                        
      </p>
   );
}

export default s20exp;