const hd320 = () => {
   return (
      <p>
         Эндоскопическая система Sonoscape HD-320 - новое предложение легендарной марки. Аппаратная стойка является золотым стандартом соотношения цены и качества. Высокое разрешение в 440 000 пикселей, многофункциональная настройка качества изображения и легкая эксплуатация с функцией гастроскопии и колоноскопии.
         <br /><br />                       
         <strong>Видеопроцессор HD-320</strong>
         <br /><br />
         • Разрешение подключаемых видеоэндоскопов – 440 000 пикселей<br />                        
         <br /><br />
         <strong>Функции</strong>
         <br /><br />
         • Структурная детализации изображения - 3 уровня<br />
         • Ручная регулировка яркости изображения - 9 уровней<br />
         • Ручная настройка цветности изображения - 38 уровней<br />
         • Автоматическая регулировка яркости изображения (подавление бликов)<br />
         • Ручное изменение зоны экспозиции, позволяет оптимизировать работу функции<br />
         • Подавления бликов в зависимости от условий исследования - 3 уровня<br />
         • Режим стоп-кадра, запускаемый с рукоятки эндоскопа или педали<br />
         • Автоматический баланс белого
         <br /><br />
         <strong>Светодиодный источник света HDL-320E</strong>
         <br /><br />
         • Основная лампа – светодиодная 5700К, 50 Вт, 800 люмен,<br />
         • Срок службы до 50 000 часов<br />
         • Запасная лампа - галогеновая<br />
         • Ручная регулировка яркости освещения<br />
         • Электрическая помпа<br />
         <br /><br />
         <strong>Цифровой медицинский монитор высокой четкости</strong>
         <br /><br />
         • Диагональ - 19 дюймов<br />
         • Формат – 4:3<br />
         <br /><br />
         <strong>Эндоскопическая тележка HDT-320</strong>
         <br /><br />
         • Регулируемый держатель для монитора<br />
         • 4 полки (2 - регулируемые по высоте)<br /> 
         • 2 кронштейна для эндоскопов<br /> 
         <br /><br />
         <strong>Возможные для подключения видеоэндоскопы:</strong>
         <br /><br />
         • Видеогастроскоп EG-330<br />
         • Видеоколоноскоп EC-330T<br /> 
         2 кронштейна для эндоскопов<br /> 
         Ножной переключатель для управления функцией стоп-кадра                                                      
      </p> 
   );
}

export default hd320;