const probe12la = () => {
    return (
    <p>
        <br /><b>Апертура:</b> 50 мм<br /><b>Частота:</b> 3.0-17.0 МГц<br /><b>Количество элементов:</b> 256 элементов<br />
        <b>Клиническое применение:</b> поверхностные органы и структуры, периферические сосуды, скелетно-мышечные исследования, нервы
        <br /><br />            
    </p>
    );
}

export default probe12la;