const probevc62 = () => {
    return (
        <p>
            <br /><b>Радиус:</b>40 мм<br /><b>Частота:</b> 2.0-7.0 МГц<br /><b>Количество элементов:</b> 80 элемента<br />
            <b>Клиническое применение:</b> абдоминальные исследования, акушерство, гинекология.
            <br /><br />            
        </p>
    );
}

export default probevc62;