const x3v = () => {
   return (
      <p>                        
         <strong>Технические характеристики</strong>
         <br /><br />
         15,6-дюймовый медицинский монитор высокого разрешения<br />
         Один порт преобразователя<br />
         USB 2.0 / Жесткий диск 500 Гб<br />
         Wi-Fi-модуль<br />
         Встроенный аккумулятор<br />
         Блок питания15,6-дюймовый медицинский монитор высокого разрешения<br />
         Один порт преобразователя<br />
         USB 2.0 / Жесткий диск 500 Гб<br />
         Wi-Fi-модуль<br />
         Встроенный аккумулятор<br />
         Блок питания    <br />                                                  
      </p>
   );
}

export default x3v;