const x5 = () => {
   return (
      <p>
         Модель развивает успех портативного бестселлера S2N – всё в том же компактном и легком корпусе размещена мощная программно-аппаратная «начинка» с полным пакетом допплеровских режимов, покрывающая широкий спектр общих исследований, а также добавлена поддержка нового линейного датчика для уверенной диагностики глубоких сосудов.
         <br /><br />                         
         <strong>Технические характеристики</strong>
         <br /><br />
         • ЖК-монитор с диагональю 15,6”<br />
         • 1 активный порт + разветвитель для одновременной работы 3 датчиков<br />
         • Тележка с регулировкой высоты и надежной фиксацией сканера<br />
         • Вес с батареей 4,5 кг<br />
         • Полный пакет допплеровских режимов
         <br /><br />
         <strong>Режимы сканирования:</strong>
         <br /><br />
         • В, М, В/М, В/В, 4В, Тканевая гармоника, Пульсовая инверсная гармоника, Навигация биопсийной иглы (биопсийные направляющие), Режим улучшенной визуализации (подсветки) биопсийной иглы<br />
         • Изменение масштаба изображения в режимах реального времени и стоп-кадра<br />
         • Цветной, энергетический, направленный энергетический, направленный энергетический с высоким пространственным разрешением, импульсно-волновой, постоянно-волновой допплер<br />
         • Дуплексный, триплексный режимы<br />
         • Трапецеидальное сканирование на линейных и конвексном датчиках<br />
         • Технология подавления спекл-шума MicroScan
         <br /><br />
         <strong>Цифровая рабочая станция:</strong>
         <br /><br />
         • Жесткий диск, USB 2.0, DICOM 3.0<br />
         • Расчеты для акушерства, гинекологии, ангиологии, урологии, педиатрии, поверхностных, абдоминальных органов, кардиологии<br />
         • Составление и экспорт отчетов с возможностью добавления изображений<br />
         • Ведение БД пациентов, возможность сохранения и поиска изображений, клипов по различным полям базы данных<br />
         • Возможность записи кинопетель до 640 сек<br />                                 
      </p>
   );
}

export default x5;