const colibri = () => {
   return (
      <p>      
         Средство доставки лазерного излучения. Представляет собой оптический хэндпис, подключаемый к лазерному аппарату. Области применения аналогичны областям аппарата Mediola Compact.                  
         <br /><br /><strong>Область применения:</strong>
         <br />
         Флебология<br />  
         Проктология<br />  
         Оториноларингология<br />  
         Гинекология<br />  
         Эстетическая хирургия<br />  
         Амбулаторная хирургия<br />            
      </p>
   );
}

export default colibri;