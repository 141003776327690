const aspirator = () => {
   return (
      <p>
         <br /><br />
         Хирургический аспиратор NEW ASKIR 30 - подходит для назальной, оральной
         аспирации слизи, крови или содержимого из ЖКТ при незначительных
         хирургических (эндоскопических) вмешательствах. Легко переносное
         оборудование, предназначенное для непрерывного использования.
         Изготовлен из термостойкого, электроизоляционного пластика в
         соответствии с последними европейскими стандартами безопасности.
         Продукт поставляется с автоклавируемым поликарбонатным баком с
         перепускным клапаном и оснащен регулятором всасывания и вакуумным
         индикатором, расположенным на передней панели.
         <br /><br />                       
         <strong>Технические характеристики</strong><br /><br />

         Питание: 230 В/50 Гц<br />
         Потребление электроэнергии: 107 ват<br />
         Максимальная сила всасывания: -0,8 атм<br />
         Максимальный поток всасывания: 30 л/м<br />
         Вес 3,6 кг<br />
         Размеры(ДхШхВ), мм 350x180x210<br />
         Емкость отсасывателя: 2л<br />
         <br />                                        
      </p> 
   );
}

export default aspirator;