const plx7100a = () => {
  return (
    <p>
      <strong>Функциональные характеристики:</strong><br />
      1. Большой динамический плоскопанельный детектор размером 30 см × 30 см четко отображает детали каждой анатомической структуры.<br />
      2. 16-битная шкала оттенков серого высочайшего качества, четкое отображение контуров мягких тканей и костной ткани человека, помогает оператору в позиционировании и проведении процедур.<br />
      3. Максимальная выходная мощность до 25 кВт, импульсный ток до 100 мА, защита качества изображения, получение импульсов высокого тока, когда требуется непрерывное воздействие большой дозы.<br />
      4. Интеллектуальная система программного обеспечения рабочей станции, включая функцию постобработки изображений, функцию улучшения краев, функцию рекурсивного шумоподавления, оптимизированную для получения четких клинических изображений.<br />
      5. Выравнивание тканей в реальном времени посредством пирамиды изображений в реальном времени, динамическая гамма-коррекция в реальном времени.<br />
      6. Регулировка ширины и положения окна в реальном времени, поворот в реальном времени под любым углом, масштабирование в реальном времени, панорамирование, точки интереса, инверсия, шумоподавление, сглаживание, повышение резкости и другие полезные и мощные функции обработки изображений, обеспечивающие более надежную диагностику и уверенность.<br />
      7. Специализированный медицинский ЖК-дисплей высокой четкости, предоставляющий вам клинические изображения высокой яркости и контрастности; контраст естественный, а детали изображений, полезные для диагностики, отображаются более четко и на более высоком уровне.<br />
      8. Идеальные цифровые изображения гарантируют, что вы сможете легко справиться с различными рутинными операциями в нескольких областях.<br /><br />
      <strong>Клиническое применение:</strong><br />
      Mожет использоваться в ортопедии, общей хирургии, ортопедической хирургии, травматологической хирургии, урологии, хирургии позвоночника, гастроэнтерологии, гинекологии и т. д.
    </p>
  );
}
   
export default plx7100a;