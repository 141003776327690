const lx3 = () => {
   return (
      <p>  
        Acclarix LX3 сочетает в себе компактный внешний вид, передовые технологии и интеллектуальный рабочий процесс, чтобы обеспечить более ценные инновации. Его отличное качество изображения играет важную роль в профилактике и раннем выявлении в первичной медицинской помощи, обогащая диагностическое применение и повышая клиническую ценность.                      
         <strong>Технические характеристики</strong>
         <br /><br />
         • 64 физических канала<br />
         • 21,5-дюймовый ЖК-монитор с высоким разрешением<br />
         • 5 разъемов для датчиков<br />
         • Полуавтоматическая подъемная панель<br />
         • Твердотельный накопитель емкостью 512 Гб<br />
         • Клавиатура с подсветкой<br />
         • Программное обеспечение<br />
         • TAI-Адаптивная визуализация тканей<br />
         • Адаптивная доплеровская визуализация<br />
         • Изображение с частотным компаундированием<br />
         • Адаптивная пространственная композиционная визуализация<br />
         • Гармоническое изображение<br />
         • eSRI-Адаптивная визуализация с уменьшением спеклов<br />
         • Расширение спектра<br />
         • Цифровое многолучевое формирование<br />
         • Трапецивидное изображение<br />
         • Расширенное поле зрения<br />
         • Отклонение в B-режиме<br />
         • Цифровой зум<br />
         • Масштабирование во весь экран<br />
         • Автоматическая трассировка<br />
         • Улучшенная визуализация иглы<br />
         • HPRF (высокая частота повторения импульсов)<br />
         • TDI-Тканевая допплерография<br />
         • Анатомический М-режим<br />
         • Цветной M-режим<br />
         • Кабель заземления<br />
         • Кабель питания<br />
         • Основное руководство пользователя Acclarix LX3<br />
         • Краткое руководство Acclarix LX3<br />
         • Расширенное руководство пользователя Acclarix LX3                               <br />       
      </p>
   );
}

export default lx3;