const s2n = () => {
   return (
      <p>
         Портативный ультразвуковой сканер SonoScape S2N обладает удобной формой, эффективными функциями и необычайно высокими для своего класса качествами. Так, чувствительность режимов доплера этого УЗИ аппарата находится на уровне стационарных аналогов.
         <br /><br />
         Кроме того, данное устройство доступнее в эксплуатации благодаря рекордно малому весу. По этому показателю S2N от официального производителя SonoScape превосходит аналоги, что, наряду с ценой, обусловило популярность данной модели среди многих специалистов.
         <br /><br />                         
         <strong>Технические характеристики</strong>
         <br /><br />
         • Большой ЖК-монитор с диагональю 15,6”<br />
         • 1 порт для подключения датчиков<br />                      
         • Вес с батареей 4,5 кг
         <br /><br />
         <strong>Режимы сканирования:</strong>
         <br /><br />
         • В, М, В/М, В/В, 4В, Тканевая гармоника<br />
         • Изменение масштаба изображения в режимах реального времени и стоп-кадра<br />
         • Цветной, энергетический, направленный энергетический, импульсно-волновой допплер, постоянно-волновой<br />
         • Дуплексный, триплексный режимы<br />
         • Трапецеидальное сканирование на линейных датчиках<br />
         • Технология подавления спекл-шума MicroScan<br />
         <br /><br />
         <strong>Цифровая рабочая станция:</strong>
         <br /><br />
         • Жесткий диск 500 Гб, USB 2.0, DICOM 3.0<br />
         • Расчеты для акушерства, гинекологии, ангиологии, урологии, ортопедии<br />
         • Составление и импорт отчетов с возможностью добавления изображений<br />
         • Ведение БД пациентов, возможность сохранения и поиска изображений, клипов, 3D-образов по различным полям базы данных                                                        
      </p>
   );
}

export default s2n;