const unilighti = () => {
   return (
      <p>      
         Универсальный световодный инструмент, с торцевым выходом излучения под углом 22°, предназначен для проведения эндоскопических операций.                
         <br /><br /> 
         <strong>Длина:</strong> 3000мм<br /> 
         <strong>Диаметр:</strong> 400мкм / 600мкм
         <br /><br />  
         <strong>Область применения:</strong>
         <br /> 
         Эндоскопия<br />  
         Урология<br />            
      </p>
   );
}

export default unilighti;