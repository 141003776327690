const detro_forte = () => {
    return (
        <>
            Химический состав (ингредиенты):<br /> 
            Концентрат на основе 2% глутарового альдегида<br /> 
            • ДВУ - 15 мин.<br /> 
            Продолжительность использования раствора составляет до 28 дней.<br /> 
            Области применения:<br /> 
            Используется для дезинфекции и холодной стерилизации общехирургических и анестезиологических инструментов, гибких и жестких эндоскопов  и инструментов к ним<br /> 
            Концентрат, объем - 5 л.<br /> 

        </>
    );
}

export default detro_forte;