const p15 = () => {
   return (
      <p>
                                    
         Тонкий баланс высокой производительности и экономической эффективности, обеспечивает превосходное качество изображения и предоставляет ряд полезных инструментов. При этот нет никаких компромиссов с эргономичным дизайном, упрощенным рабочим процессом и летким доступом к клинической информации.<br /><br />
         <strong>Технические характеристики</strong><br /><br />
         • 21,5-дюймовый LED монитор высокой четкости.<br />
         • 13,3-дюймовая сенсорная панель с быстрым временем отклика.<br />
         • Полный спектр ультрасовременной технологии обработки изображений, м-Scan, компаудинг, инверсная тканевая гармоника и др.<br />
         • Инновационные функции: Панорамное санирование, C-xlasto imaging, 3D/4D и другие.<br />
         • Пакеты автоматического расчета и автоматической оптимизации: Auto Color, Auto IMT, Auto
         EF and so on.<br />
         • Встроенный аккумулятор большой ёмкости.<br />
         • DICOM, Wi-Fi, Bluetooth.<br />                     
      </p>
   );
}

export default p15;