const s9exp = () => {
   return (
      <p>
         Этот портативный ультразвуковой аппарат стал настоящим прорывом в области УЗИ технологий. Эта мощная и многофункциональная переносная система вполне сравнима со стационарными аналогами, а зачастую способна дать им фору. Традиционно высокое для SonoScape качество изображения вышло на новый уровень!
         <br /><br />
         Компактный и изящный УЗИ аппарат создан в стремлении к совершенству, что нашло отражение в многочисленных технологических опциях. Благодаря высокой мобильности и конкурентной цене, он станет незаменимым помощником при проведении исследований как в медицинских центрах, так и при выезде специалиста к пациенту.
         <br /><br />                         
         <strong>Технические характеристики</strong>
         <br /><br />
         • Большой ЖК-монитор с диагональю 15”<br />
         • 2 активных порта для подключения датчиков,<br />
         • Сенсорная панель управления<br />                        
         • Встроенная батарея, от 90 минут работы в режиме сканирования<br />
         • Вес 9 кг.
         <br /><br />
         <strong>Режимы сканирования:</strong>
         <br /><br />
         • В, М, В/М, В/В, 4В, Тканевая гармоника<br />
         • Изменение масштаба изображения в режимах реального времени и стоп-кадра<br />
         • Цветной, энергетический, направленный энергетический, импульсно-волновой допплер, HPRF (регистрация высоких скоростей кровотока), постоянно-волновой, тканевой допплер<br />
         • Дуплексный, триплексный режимы<br />
         • Трапецеидальное сканирование на линейных датчиках<br />
         • FreeHand 3D – режим поверхностной трехмерной реконструкции<br />
         • Технология подавления спекл-шума MicroScan<br />
         • Compound Imaging (режим раскачки УЗ-луча)<br />
         • 4D – режим трехмерной реконструкции в реальном времени<br />
         • Анатомический М-режим, Цветной М-режим, Панорамное сканирование<br />
         • Режим соноэластографии с количественной оценкой<br />
         • Функция стресс-эхо<br />
         • Режим работы с ультразвуковыми контрастными веществами<br />
         <br /><br />
         <strong>Цифровая рабочая станция:</strong>
         <br /><br />
         • Жесткий диск 500 Гб, USB 2.0, Ethernet, DICOM 3.0<br />
         • Расчеты для акушерства, гинекологии, ангиологии, урологии, ортопедии, поверхностных, абдоминальных органов, кардиологии, возможность оценки кардио-сосудистой системы, головного мозга плода<br />
         • Составление и импорт отчетов с возможностью добавления изображений<br />
         • Ведение БД пациентов, возможность сохранения и поиска изображений, клипов, 3D-образов по различным полям базы данных                                                        
      </p>
   );
}

export default s9exp;