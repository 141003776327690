const mediola_compact = () => {
   return (
      <p>                        
         <strong>Параметры:</strong>
         <br /><br />
         • Акваспецифичный диапазон (W-диапазон) - 1560 нм | 15 Вт<br />  
         • Двухволновой аппарат: Гемоглобин-специфичный диапазон (W-диапазон) - 970 нм | 30 Вт<br />  
         • Длительность импульса излучения - 0,1...0,9 с<br />  
         • Система охлаждения - автономная, воздушная<br />  
         • Мощность - 15 / 30 Вт<br />  
         • Электропитание - 230 В, 50 Гц<br />  
         • Дисплей - цветной, сенсорный<br />  
         • Потребляемая мощность - не более 300 Вт<br />  
         • Размер дисплея - 4,3''<br />  
         • Гарантия производителя - 24 мес<br />  
         • Форм-фактор - мобильный моноблок<br />  
         <br /><br />
         <strong>Управление:</strong>
         <br /><br />
         • Сенсорный графический дисплей с меню на русском языке<br />  
         • Предварительная установка параметров излучения, возможность переключения между ними в ходе операции<br />  
         • Изменение яркости пилотного луча<br />  
         • Подача ЛИ посредством педали<br />  
         • Сохранение параметров при выключении аппарата<br />  
         <br /><br />
         <strong>Безопасность:</strong>
         <br /><br />
         • Функция контроля исправности световодного инструмента<br />  
         • Блокировка подачи излучения при неустановленном инструменте<br />  
         • Функция самотестирования системы<br />  
         <br /><br />
         <strong>Преимущества:</strong>
         <br /><br /> 
         • Держатель световода Mediola Colibri в базовой комплектации для комфортной работы и сокращения расходов на операцию<br />  
         • Система контроля стабильности параметров излучения для лучшей предсказуемости результатов операции<br />  
         <br /><br />
         <strong>Область применения:</strong>
         <br /><br />  
         Флебология<br />  
         Проктология<br />  
         Оториноларингология<br />  
         Гинекология<br />  
         Эстетическая хирургия<br />  
         Амбулаторная хирургия<br />            
      </p>
   );
}

export default mediola_compact;