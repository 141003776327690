const probe10i2 = () => {
    return (
        <p>
            <br /><b>Центральная частота:</b> 7.5 МГц<br /><b>Апертура:</b> 25 мм<br />
            <b>Клиническое применение:</b>  Клиническое применение: поверхностные органы и структуры, скелетно-мышечные исследования, нервы, периферические сосуды, интраоперационные исследования
            <br /><br />            
        </p>
    );
}

export default probe10i2;