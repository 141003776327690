const viv60 = () => {
    return (
        <p>
         Является оптимальным решением для всех клинических ультразвуковых исследований. Комбинация технологий оптимизации качества изображения, широкополосного цифрового формирования луча, динамической фокусировки, динамической апертуры в реальном режиме времени, цветного допплера и многое другое, делает этот сканер отличной цифровой ультразвуковой системой, и обеспечивает полное ультразвуковое диагностирование в области брюшных, кардиологических, акушерских, гинекологических, урологических, ортопедических исследованиях, а также  исследовании поверхностных тканей и прилегающих периферийных сосудов, педиатрии.
<br /><br />
         <strong>Технические характеристики</strong><br />
         21,5-дюймовый медицинский дисплей можно поворачивать, растягивать и складывать;<br />
13,3-дюймовый высокочувствительный сенсорный экран, максимальный угол поворота
50 градусов;<br />
Регулируемая высота консоли и поворот;<br />
4 разъема датчиков;<br />
Съемная нагревательная емкость для геля, 37 °/40 ° опционально;<br />
Жесткий диск емкостью 1TB, DVD-R/W и 4 порта USB;<br />
Интерфейсы: видео, S-Video, VGA, HDMI, Ethernet, DICOM3.0, принтер;<br />
Режим изображения: B, 2B/4B, M, AMM, изогнутый AMM, CM, CDFI, PDI, DPDI, CW, PW, TDI,
3D/4D;<br />
Пакеты программного обеспечения для измерения и расчета: брюшная полость，акушер
ство и гинекология, сердце，сосудистая ，урология，малые органы；
модуль 4D+модуль CW;<br />
EFOV, Панорамный, ZoneElast, SCI, FCI, ZClear, PIHI, THI, TSI, 3D/4D, IMT, HR-Flow,
zMicroFlow;
PW Авто-огибающая и расчет;<br />
Сердечно-сосудистые заболевания: CW, TDI, IMT，Автоматический EF；<br />
zWorkFlow (индивидуальный рабочий процесс);<br />
Акушерство и гинекология: измерение тазового дна, 3D/4D, ZLive (5D), Smart OB, Auto NT,
Auto Follicle <br /> 
        </p>
    );
}

export default viv60;