const eg430 = () => {
   return (
      <p>
         <br /><br /> 
         <strong>Видеогастроскоп EG-430 / EG-430L</strong>
         <br /><br /> 
         • Длина 1 350 мм.<br /> 
         • Длина рабочая 1 050 мм.<br /> 
         • Диаметр 9,3 / 9,8 мм.<br /> 
         • Рабочий канал 2,8 / 3,2 мм.<br /> 
         • Угол обзора 140°<br /> 
         • Двухканальный (есть дополнительный канал для растворов/жидкостей)
         <br />                                        
      </p>
   );
}

export default eg430;