const probebcl105 = () => {
    return (
        <p>
            <br /><b>Центральная частота:</b> 6,5/7,5 МГц<br /><b>Апертура:</b> 60 мм<br /><br /><b>Угол обзора: </b>200°<br />
            <b>Клиническое применение:</b>  Клиническое применение: урология
            <br /><br />            
        </p>
    );
}

export default probebcl105;