const viv80s = () => {
    return (
        <p>
         Современная комбинация технологий оптимизации качества изображения, широкополосного цифрового формирования луча, динамической фокусировки, динамической апертуры в реальном режиме времени, цветного допплера и многое другое, делает этот сканер отличной цифровой ультразвуковой системой и обеспечивает полное ультразвуковое диагностирование в области брюшных, кардиологических, акушерских, гинекологических, урологических, ортопедических исследованиях, а также  исследовании поверхностных тканей и прилегающих периферийных сосудов, педиатрия.

Применены технологии для широкого спектра использования: Эластография, пространственный компаундинг, панорамная визуализация, трапециевидное изображение, 4D исследования
<br /><br />
         <strong>Технические характеристики</strong><br />
         Дизайн функций : 21,5-дюймовый медицинский дисплей можно поворачивать, растягивать и складывать. Вы
сокочувствительный сенсорный экран 13,3 дюйма, поворот на 60 градусов. Регулируемая высота консоли и вра
щение клавиатуры. 5 активированных разъемов датчиков.<br />
Режим изображения : B, B рулевое управление, 2B/4B, M, B/M, CDFI, PDI, CM, PW, AMM, CW, TDI, панора
мный, цветной панорамный, трапециевидный, 3D/4D.<br />
Клинические применения : брюшная полость, кардиология, акушерство, гинекология, урология, педиатрия,
мелкие детали, сосуды, поверхностные заболевания, ортопедия, MSK, нервы, индивидуальные.<br />
Передовая техника визуализации расширенная визуализация (EFov), тканеспецифическая визуализация
(TSI), гармоническая визуализация тканей (THI), визуализация с уменьшением спеклов (SRI), пространственная
композитная визуализация (SCI), частотно-композитная визуализация (FCI), хроматическая визуализация B/C/D.<br />
Преимущество : Автоматическая оптимизация изображения одной кнопкой. Поддержка однокристаллическог
о изображения. Встроенный DVD-ROM.<br />
Интерфейс данных: Видео, S-Video, аудиовход/выход, DVI, VGA, HDMI, RS-232, RJ45, DICOM3.0, USB, пор
т принтера,
стандартный ЭКГ модуль.<br />
Интеллектуальное измерение: авто IMT, авто PW, авто NT.<br />
Большая емкость: 1 ТБ.<br />
        </p>
    );
}

export default viv80s;