const p40 = () => {
   return (
      <p>
                                    
         Новая ультразвуковая диагностическая система на базе платформы WIS+, обеспечивающая превосходный уровень визуализации и поддержку нейросетей для автоматизированных расчетов. Система выполнена в компактном корпусе, оснащена большим ЖК-монитором с диагональю 21,5'', удобным сенсорным дисплеем 13,3'' и регулируемой панелью управления. Подходит для всех видов исследований и отличается сверхчувствительными допплеровскими режимами.<br /><br />
         <strong>Технические характеристики</strong><br />
         21.5 дюймовый LED монитор высокой четкости с шарнирным рычагом.<br />
         13.3 дюймовая наклоняемая сенсорная панель управления с чувствительной обратной связью и точным позиционированием нажатий.<br />
         5 активных портов для датчиков и 1 порт для карандашного датчика доступны для широкого спектра применений и повышают эффективность повседневной работы.<br />
         Упрощенная панель управления помогает оптимизировать рабочий процесс обследования и сократить общее время работы.<br />
         Настраиваемая клавиатура повышает гибкость для различных пользовательских предпочтений и параметров рабочего процесса.<br />
         Регулируемая высота консоли и поворот панель управления в соответствии с предпочтениями пользователя.<br />
         Встроенный аккумулятор позволяет P40 работать без источника питания в случае чрезвычайных ситуаций, таких как сбой питания, и повышает его мобильность для удобства.<br />
      </p>
   );
}

export default p40;