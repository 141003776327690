const s60 = () => {
   return (
      <p>
         Новинка! SonoScape S60 – первая система от SonoScape с полноценным искусственным интеллектом (AI) на борту. Нейросети функции AI прошли обучение на десятках тысяч реальных изображений. В сочетании с ультразвуковым изображением беспрецедентно высокой четкости – мы получаем диагностический комплекс нового поколения с расширенными возможностями в кардиологии и акушерстве. Теперь специалисты УЗД избавлены от рутины, которая всегда мешала сосредоточиться на том, что действительно важно.
         <br /><br />                         
         <strong>Технические характеристики</strong>
         <br /><br />
         • Большой ЖК-монитор с диагональю 21,5”<br /> 
         • 4 активных порта для подключения датчиков, 1 паркинговый порт, 1 порт для карандашного датчика<br /> 
         • Сенсорная панель управления 13,3”<br /> 
         • Электрическая регулировка панели управления по высоте, механическая регулировка по углу<br /> 
         <br /><br />
         <strong>Режимы сканирования:</strong>
         <br /><br />
         • В, М, В/М, В/В, 4В, Тканевая гармоника, Режим улучшенной визуализации биопсийной иглы<br />
         • Три технологии сосудистой визуализации – SRF, Bright Flow, Micro F для уверенной визуализации самых мелких сосудов<br />
         • Весь пакет допплеровских режимов, Strain Rate<br />
         • Комплект Smart-функций – 14 Smart режимов для автоматизации исследований в различных областях медицины<br />
         • Трапецеидальное сканирование на линейных датчиках<br />
         • FreeHand 3D – режим поверхностной трехмерной реконструкции<br />
         • 4D – режим трехмерной реконструкции в реальном времени<br />
         • Анатомический М-режим, Цветной М-режим, Панорамное сканирование в базовой комплектации<br />
         • Режим СоноЭластографии с количественной оценкой<br />
         • Функция Стресс-Эхо в базовой комплектации<br />
         • Технология подавления спекл-шума MicroScan 5 поколения<br />
         • Подогреватель геля – стандартное оснащение<br />
         <br /><br />
         <strong>Цифровая рабочая станция:</strong>
         <br /><br />
         • Жесткий диск 1000 Гб, USB 3.0, Ethernet, DICOM 3.0, Wi-Fi<br />
         • Новый ЦАП, обновленный процессор и видеокарта для уверенной работы в 3D и 4D<br />
         • Расчеты для акушерства, гинекологии, ангиологии, урологии, ортопедии, поверхностных, абдоминальных органов, кардиологии, головного мозга и сердца плода (в том числе STIC), вывода кривых роста плода, автоматический анализ толщины комплекса интима-медиа<br />
         • Составление и импорт отчетов с возможностью добавления изображений
         • Ведение БД пациентов, возможность сохранения и поиска изображений, клипов, 3D-образов по различным полям базы данных<br />
         • Встроенный учебник Sono-Help – библиотека изображений, стандартные положения датчиков для уверенной работы молодых специалистов<br />
         • Возможность трансляции изображения на мобильные устройства, сервис удаленного консилиума                                                       
      </p>
   );
}

export default s60;