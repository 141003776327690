const detro_opa = () => {
    return (
        <>
            Химический состав (ингредиенты):<br /> 
            % 0,55 ортофталевый альдегид, вспомогательные вещества<br /> 
            • ДВУ - 5 мин.<br /> 
            Продолжительность использования раствора составляет 14 дней.<br /> 
            Области применения:<br /> 
            Используется для дезинфекции и холодной стерилизации общехирургических и анестезиологических инструментов, гибких и жестких эндоскопов  и инструментов к ним<br /> 
            Концентрат, объем - 5 л.<br /> 
        </>
    );
}

export default detro_opa;