const probel742 = () => {
    return (
        <p>
            <br /><b>Апертура:</b> 50 мм<br /><b>Частота:</b> 4.0-16.0 МГц<br /><b>Количество элементов:</b> 128 элементов<br />
            <b>Клиническое применение:</b> поверхностные органы и структуры, периферические сосуды, скелетно-мышечные исследования, нервы
            <br /><br />            
        </p>
    );
}

export default probel742;