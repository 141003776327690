const detrowash8005 = () => {
   return (
      <p>
         Моечная машина для гибких эндоскопов с набором необходимого функционала для качественной дезинфекции. Имеется подогрев дезинфектантов, принтер для документирования процесса и ультрафиолетовая лампа для очистки воды.
         <br /><br />
         <strong>Технические характеристики</strong>
         <br /><br />
         • Обрабатывает единовременно 2 гибких эндоскопа с тремя и более каналами.<br />
         • Один бак для обработки эндоскопов<br />
         • Тест на герметичность <br />
         • Полностью автоматизированный процесс для дезинфекции гибких эндоскопов. <br />
         • Качественная очистка всех каналов. <br />
         • Возможность документирования процесса (принтер)<br />
         • Использование «традиционных» дезинфектантов  и детергентов. <br />
         • Возможность выбора оптимального алгоритма обработки эндоскопов <br />
         • Система самодезинфекции <br />
         • Программирование временных параметров цикла дезинфекции. <br />
         • Удобное эргономичное меню    <br />
         • Фильтры для очистки воды <br />
         • Время мойки – 20 мин. <br />
         • возможность изменения, хранения настроек времени и осуществления обработки в 2 режимах A, B<br />
        <br />
      </p>
   );
}

export default detrowash8005;