const u60 = () => {
   return (
      <p>
         Данная модель является совершенным этапом эволюции своих предшественников, отличается улучшенной эргономикой, современным оптимизированным дизайном, возможностями четкой и высокоэффективной визуализации органов.

УЗИ-аппарат U60 — это переходная модель от начального к высокому классу. Он оснащен увеличенным экраном: диагональ его жидкокристаллического дисплея равна 15.6˝, что позволяет специалисту лучше видеть мельчайшие элементы изображения.

Особенность U60 Edan — расширенное применение. Ультразвуковой аппарат рекомендован для абдоминальных исследований, гинекологии и акушерства, кардиологии, педиатрии, урологии, изучения малых органов, сосудов. Для U60 Edan используются конвексные, линейные, фазированные и внутриполостные датчики.


<br /><br /><strong>Технические характеристики</strong><br /><br />
• 15 дюймовый ЖК экран<br />
• Два разъема для подключения датчиков<br />
• Цветовое Допплеровское картирование<br />
• Импульсная Допплерография<br />
• Непрерывноволновая Допплерография<br />
• Спектральный Допплер с высокой частотой повторения импульсов<br />
• Направленная энергетическая Допплерография<br />
• Технология составного инверсного гармонического изображения<br />
• Технология сложно-составного сканирования<br />
• Технология уменьшения спекл-шумов<br />
• Пакет программ измерений и вычислений<br />
• Мульти-языковое программное обеспечение<br />
• 7 карт псевдо-окрашивания
         <br /><br />            
      </p>
   );
}

export default u60;