import probe2p1 from './2p1';
import probe3ca from './3c-a';
import probe4pa from './4p-a';
import probe6v1 from './6v1';
import probe6v3 from './6v3';
import probe12la from './12l-a';
import aspirator from './aspirator';
import basket from './basket';
import brush from './brush';
import probec16a from './c1-6a';
import clip from './clip';
import e1 from './e1';
import e2 from './e2';
import e3 from './e3';
import eb500 from './eb-500';
import ec430t from './ec-430t';
import ec500t from './ec-500t';
import ec550t from './ec-550t';
import ed5gt from './ed-5gt';
import eg430 from './eg-430';
import eg500 from './eg-500';
import eg550 from './eg-550';
import forceps from './forceps';
import forcepsneedle from './forcepsneedle';
import hd320 from './hd-320';
import hd350 from './hd-350';
import hd500 from './hd-500';
import hd550 from './hd-550';
import helicobacter from './helicobacter';
import hooks from './hooks';
import insuflator from './insuflator';
import irrigator from './irrigator';
import probel741 from './l741';
import mouthpiece from './mouthpiece';
import p9 from './p9';
// import p10 from './p10';
import p15 from './p15';
// import p20 from './p20';
import p25 from './p25';
import p40 from './p40';
// import p50 from './p50';
import p60 from './p60';
import propet60 from './propet60';
import propet70 from './propet70';
import propet80 from './propet80';
import p40elite from './p40elite';
import p50elite from './p50elite';
import s50elite from './s50elite';
import s2n from './s2n';
import s2 from './s2';
import s8exp from './s8exp';
import s9exp from './s9exp';
import s20exp from './s20exp';
import s40exp from './s40exp';
import s50 from './s50';
import s60 from './s60';
import snare from './snare';
import tests from './tests';
import v2000 from './v2000';
import probevc62 from './vc6-2';
import x3v from './x3v';
import x5 from './x5';
import plx112b1 from './plx112b1';
import plx118f from './plx118f';
import plx7100a from './plx7100a';
import plx5200a from './plx5200a';
import pld9600d from './pld9600d';
import mediola_compact from './mediola_compact';
import mediola_endo from './mediola_endo';
import mediola_holmium from './mediola_holmium';
import u60 from './u60'
import ax3 from './ax3';
import lx3 from './lx3';
import endoclean1000 from './endoclean1000';
import endoclean2000 from './endoclean2000';
import detrowash8005 from './detrowash8005';
// import medinova2ga from './medinova2ga';
// import medinova_dry from './medinova_dry';
// import medinova_enz from './medinova_enz';
import detro_enzym from './detro_enzym';
import detro_forte from './detro_forte';
import detro_opa from './detro_opa';
import acecide from './acecide';
import m5exp from './m5exp';
import viv60 from './viv60';
import viv80s from './viv80s';
// import probe5p1 from './5p1';
import probe5p2 from './5p2';
import probe8p1 from './8p1';
import probec311 from './c311';
import probec344 from './c344';
import probec353 from './c353';
// import probec354 from './c354';
import probec362 from './c362';
import probec542 from './c542';
import probec611 from './c611';
// import probec613 from './c613';
// import probeec95 from './ec9-5';
import probel742 from './l742';
// import probel743 from './l743';
import probel752 from './l752';
import probeve95 from './ve9-5'
import probe10i2 from './10i2';
import probebcl105 from './bcl10-5';
// import eg430l from './eg-430l';
// import ec430lt from './ec-430lt';
import ec500 from './ec-500';
// import ec500l from './ec-500l';
// import ec500lt from './ec-500lt';
import ec550 from './ec-550';
// import ec550l from './ec-550l';
// import ec550lt from './ec-550lt';
// import eg500l from './eg-500l';
// import eg550l from './eg-550l';
import mptee from './mptee';
import mpteemini from './mpteemini';
import colibri from './colibri';
import sololight from './sololight';
import duolight from './duolight';
import unilight from './unilight';
import unilightg from './unilightg';
import unilighti from './unilighti';

const products = [
   
    {id: "hd-320", caption: "HD-320", subtitle: "Видеоэндоскопическая система", group: "endoscopy", subgroup: "Видеоэндоскопические системы", content: hd320},
    {id: "hd-350", caption: "HD-350", subtitle: "Видеоэндоскопическая система", group: "endoscopy", subgroup: "Видеоэндоскопические системы", content: hd350},
    {id: "hd-500", caption: "HD-500", subtitle: "Видеоэндоскопическая система", group: "endoscopy", subgroup: "Видеоэндоскопические системы", content: hd500},
    {id: "hd-550", caption: "HD-550", subtitle: "Видеоэндоскопическая система", group: "endoscopy", subgroup: "Видеоэндоскопические системы", content: hd550},
    {id: "eg-430", caption: "EG-430 / EG-430L", subtitle: "Видеогастроскоп", group: "endoscopy", subgroup: "Видеогастроскопы", content: eg430},
    {id: "ec-430t", caption: "EC-430T / EC-430L/T", subtitle: "Видеоколоноскоп", group: "endoscopy", subgroup: "Видеоколоноскопы", content: ec430t},
    {id: "eg-500", caption: "EG-500 / EG-500L", subtitle: "Видеогастроскоп", group: "endoscopy", subgroup: "Видеогастроскопы", content: eg500},
    {id: "ec-500t", caption: "EC-500T / EC-500L/T", subtitle: "Видеоколоноскоп", group: "endoscopy", subgroup: "Видеоколоноскопы", content: ec500t},
    {id: "eg-550", caption: "EG-550 / EG-550L", subtitle: "Видеогастроскоп", group: "endoscopy", subgroup: "Видеогастроскопы", content: eg550},
    {id: "ec-550t", caption: "EC-550T / EC-550L/T", subtitle: "Видеоколоноскоп", group: "endoscopy", subgroup: "Видеоколоноскопы", content: ec550t},
    // {id: "eg-430l", caption: "EG-430L", subtitle: "Видеогастроскоп", group: "endoscopy", subgroup: "Видеогастроскопы", content: eg430l},
    // {id: "eg-500l", caption: "EG-500L", subtitle: "Видеогастроскоп", group: "endoscopy", subgroup: "Видеогастроскопы", content: eg500l},
    // {id: "ec-430lt", caption: "EC-430L/T", subtitle: "Видеоколоноскоп", group: "endoscopy", subgroup: "Видеоколоноскопы", content: ec430lt},
    {id: "ec-500", caption: "EC-500 / EC-500L", subtitle: "Видеоколоноскоп", group: "endoscopy", subgroup: "Видеоколоноскопы", content: ec500},
    // {id: "ec-500l", caption: "EC-500L", subtitle: "Видеоколоноскоп", group: "endoscopy", subgroup: "Видеоколоноскопы", content: ec500l},
    // {id: "ec-500lt", caption: "EC-500L/T", subtitle: "Видеоколоноскоп", group: "endoscopy", subgroup: "Видеоколоноскопы", content: ec500lt},
    {id: "ec-550", caption: "EC-550 / EC-550L", subtitle: "Видеоколоноскоп", group: "endoscopy", subgroup: "Видеоколоноскопы", content: ec550},
    // {id: "ec-550l", caption: "EC-550L", subtitle: "Видеоколоноскоп", group: "endoscopy", subgroup: "Видеоколоноскопы", content: ec550l},
    // {id: "ec-550lt", caption: "EC-550L/T", subtitle: "Видеоколоноскоп", group: "endoscopy", subgroup: "Видеоколоноскопы", content: ec550lt},
    {id: "l741", caption: "L741", subtitle: "Датчик линейный", group: "probes", subgroup: "", content: probel741},
    {id: "l742", caption: "L742 / L743", subtitle: "Датчик линейный", group: "probes", subgroup: "", content: probel742},
    // {id: "l743", caption: "L743", subtitle: "Датчик линейный", group: "probes", subgroup: "", content: probel743},
    {id: "l752", caption: "L752", subtitle: "Датчик линейный", group: "probes", subgroup: "", content: probel752},
    {id: "12l-a", caption: "12L-A", subtitle: "Датчик линейный монокристальный", group: "probes", subgroup: "", content: probe12la},
    {id: "3c-a", caption: "3C-A", subtitle: "Датчик конвексный", group: "probes", subgroup: "", content: probe3ca},
    {id: "c344", caption: "C344", subtitle: "Датчик конвексный", group: "probes", subgroup: "", content: probec344},
    {id: "c353", caption: "C353 / C354", subtitle: "Датчик конвексный", group: "probes", subgroup: "", content: probec353},
    // {id: "c354", caption: "C354", subtitle: "Датчик конвексный", group: "probes", subgroup: "", content: probec354},
    {id: "c362", caption: "C362", subtitle: "Датчик конвексный", group: "probes", subgroup: "", content: probec362},
    {id: "c542", caption: "C542", subtitle: "Датчик конвексный", group: "probes", subgroup: "", content: probec542},
    {id: "c311", caption: "C311", subtitle: "Датчик микроконвексный", group: "probes", subgroup: "", content: probec311},
    {id: "c611", caption: "C611 / C613", subtitle: "Датчик микроконвексный", group: "probes", subgroup: "", content: probec611},
    // {id: "c613", caption: "C613", subtitle: "Датчик микроконвексный", group: "probes", subgroup: "", content: probec613},
    {id: "c1-6a", caption: "C1-6A", subtitle: "Датчик конвексный монокристальный", group: "probes", subgroup: "", content: probec16a},
    {id: "6v1", caption: "6V1", subtitle: "Датчик ректовагинальный", group: "probes", subgroup: "", content: probe6v1},
    {id: "6v3", caption: "6V3", subtitle: "Датчик ректовагинальный", group: "probes", subgroup: "", content: probe6v3},
    // {id: "ec9-5", caption: "EC9-5", subtitle: "Датчик ректовагинальный", group: "probes", subgroup: "", content: probeec95},
    {id: "ve9-5", caption: "VE9-5", subtitle: "Датчик ректовагинальный", group: "probes", subgroup: "", content: probeve95},
    {id: "2p1", caption: "2P1 / 5P1", subtitle: "Датчик фазированный", group: "probes", subgroup: "", content: probe2p1},
    // {id: "5p1", caption: "5P1", subtitle: "Датчик фазированный", group: "probes", subgroup: "", content: probe5p1},
    {id: "5p2", caption: "5P2", subtitle: "Датчик фазированный", group: "probes", subgroup: "", content: probe5p2},
    {id: "8p1", caption: "8P1", subtitle: "Датчик фазированный", group: "probes", subgroup: "", content: probe8p1},
    {id: "4p-a", caption: "3P-A / 4P-A", subtitle: "Датчик фазированный", group: "probes", subgroup: "", content: probe4pa},
    {id: "vc6-2", caption: "VC6-2 / VC2-9", subtitle: "Датчик объемный конвексный", group: "probes", subgroup: "", content: probevc62},
    {id: "10i2", caption: "10I2", subtitle: "Датчик линейный интрооперационный", group: "probes", subgroup: "", content: probe10i2},
    {id: "mptee", caption: "MPTEE", subtitle: "Чреспищеводный датчик", group: "probes", subgroup: "", content: mptee},
    {id: "mpteemini", caption: "MPTEE mini", subtitle: "Чреспищеводный датчик", group: "probes", subgroup: "", content: mpteemini},
    {id: "bcl105", caption: "BCL10-5", subtitle: "Датчик биплановый внутриполостной", group: "probes", subgroup: "", content: probebcl105},
    {id: "eb-500", caption: "EB-500", subtitle: "Видеобронхоскоп", group: "endoscopy", subgroup: "Прочие видеоэндоскопы", content: eb500},
    {id: "ed-5gt", caption: "ED-5GT", subtitle: "Видеодуоденоскоп", group: "endoscopy", subgroup: "Прочие видеоэндоскопы", content: ed5gt},
    {id: "helicobacter", caption: "HUBT-20P", subtitle: "Тестер Helicobacter Pylori", group: "other", subgroup: "", content: helicobacter},
    {id: "tests", caption: "HUBT-20P", subtitle: "Тесты Helicobacter Pylori", group: "other", subgroup: "", content: tests},
    {id: "insuflator", caption: "JSQB-P1", subtitle: "Эндоскопический СО2 инсуфлятор", group: "other", subgroup: "", content: insuflator},
    {id: "irrigator", caption: "JSFB-P1", subtitle: "Эндоскопический ирригатор", group: "other", subgroup: "", content: irrigator},
    {id: "aspirator", caption: "NEW ASKIR 30", subtitle: "Хирургический аспиратор", group: "other", subgroup: "", content: aspirator},
    {id: "p9", caption: "P9 / P10", subtitle: "Удобное решение для точных диагнозов", group: "ultrasound", subgroup: "P - серия", content: p9},
    // {id: "p10", caption: "P10", subtitle: "Гибкие решения в ультразвуковой диагностике", group: "ultrasound", subgroup: "P - серия", content: p10},
    {id: "p15", caption: "P15 / P20", subtitle: "Улучшенные ультразвуковые технологии", group: "ultrasound", subgroup: "P - серия", content: p15},
    // {id: "p20", caption: "P20", subtitle: "Ценность и значимость", group: "ultrasound", subgroup: "P - серия", content: p20},
    {id: "p25", caption: "P25", subtitle: "Стабильная производительность", group: "ultrasound", subgroup: "P - серия", content: p25},
    {id: "p40", caption: "P40 / P50", subtitle: "Опыт и ценности", group: "ultrasound", subgroup: "P - серия", content: p40},
    // {id: "p50", caption: "P50", subtitle: "Расширьте свой взгляд", group: "ultrasound", subgroup: "P - серия", content: p50},
    {id: "p60", caption: "P60 / P60Exp", subtitle: "Выраженный интеллект", group: "ultrasound", subgroup: "P - серия", content: p60},
    {id: "s20exp", caption: "S20Exp", subtitle: "Совершенство во всем", group: "ultrasound", subgroup: "S - серия", content: s20exp},
    {id: "s40exp", caption: "S40Exp", subtitle: "Стиль и производительность", group: "ultrasound", subgroup: "S - серия", content: s40exp},
    {id: "s50", caption: "S50", subtitle: "Показать невидимое", group: "ultrasound", subgroup: "S - серия", content: s50},
    {id: "s60", caption: "S60", subtitle: "Вдохновляющий интеллект", group: "ultrasound", subgroup: "S - серия", content: s60},
    {id: "p40elite", caption: "P40 ELITE", subtitle: "Серия Elite", group: "ultrasound", subgroup: "Серия ELITE", content: p40elite},
    {id: "p50elite", caption: "P50 ELITE", subtitle: "Серия Elite", group: "ultrasound", subgroup: "Серия ELITE", content: p50elite},
    {id: "s50elite", caption: "S50 ELITE", subtitle: "Серия Elite", group: "ultrasound", subgroup: "Серия ELITE", content: s50elite},
    {id: "e1", caption: "E1", subtitle: "Для всех ваших потребностей", group: "ultrasound", subgroup: "Портативные УЗ системы", content: e1},
    {id: "e2", caption: "E2", subtitle: "Для всех ваших потребностей", group: "ultrasound", subgroup: "Портативные УЗ системы", content: e2},
    {id: "e3", caption: "E3", subtitle: "Для всех ваших потребностей", group: "ultrasound", subgroup: "Портативные УЗ системы", content: e3},
    {id: "s2", caption: "S2", subtitle: "Ваши ценности и приоритеты", group: "ultrasound", subgroup: "Портативные УЗ системы", content: s2},
    {id: "s2n", caption: "S2N", subtitle: "Ваши ценности и приоритеты", group: "ultrasound", subgroup: "Портативные УЗ системы", content: s2n},
    {id: "s8exp", caption: "S8Exp", subtitle: "Гибкость и универсальность", group: "ultrasound", subgroup: "Портативные УЗ системы", content: s8exp},
    {id: "s9exp", caption: "S9 / S9Exp / S9Pro", subtitle: "Гибкость и универсальность", group: "ultrasound", subgroup: "Портативные УЗ системы", content: s9exp},
    // {id: "s2", caption: "S2", subtitle: "Ваши ценности и приоритеты", group: "ultrasound", subgroup: "S - серия", content: s2},
    // {id: "s2n", caption: "S2N", subtitle: "Ваши ценности и приоритеты", group: "ultrasound", subgroup: "S - серия", content: s2n},
    // {id: "s8exp", caption: "S8Exp", subtitle: "Гибкость и универсальность", group: "ultrasound", subgroup: "S - серия", content: s8exp},
    // {id: "s9exp", caption: "S9 / S9Exp / S9Pro", subtitle: "Гибкость и универсальность", group: "ultrasound", subgroup: "S - серия", content: s9exp},
    {id: "x5", caption: "X3 / X5", subtitle: "Высокий класс", group: "ultrasound", subgroup: "Портативные УЗ системы", content: x5},
    {id: "propet60", caption: "ProPet 60", subtitle: "Новинка для ветеринарии", group: "veterinary", subgroup: "", content: propet60},
    {id: "propet70", caption: "ProPet 70", subtitle: "Новинка для ветеринарии", group: "veterinary", subgroup: "", content: propet70},
    {id: "propet80", caption: "ProPet 80", subtitle: "Новинка для ветеринарии", group: "veterinary", subgroup: "", content: propet80},
    {id: "v2000", caption: "V2000", subtitle: "Ветеринарная эндоскопическая система", group: "veterinary", subgroup: "", content: v2000},
    {id: "x3v", caption: "X3V / X5V", subtitle: "Почувствуйте разницу", group: "veterinary", subgroup: "", content: x3v},
    {id: "brush", caption: "Чистящие щетки", subtitle: "", group: "instruments", subgroup: "", content: brush},
    {id: "forcepsneedle", caption: "Щипцы биопсийные с иглой", subtitle: "", group: "instruments", subgroup: "", content: forcepsneedle},
    {id: "forceps", caption: "Щипцы биопсийные", subtitle: "", group: "instruments", subgroup: "", content: forceps},
    {id: "snare", caption: "Петли для полипэктомии", subtitle: "", group: "instruments", subgroup: "", content: snare},
    {id: "clip", caption: "Клипсы", subtitle: "", group: "instruments", subgroup: "", content: clip},
    {id: "hooks", caption: "Щипцы захватывающие", subtitle: "", group: "instruments", subgroup: "", content: hooks},
    {id: "basket", caption: "Корзина захватывающая", subtitle: "", group: "instruments", subgroup: "", content: basket},
    {id: "mouthpiece", caption: "Загубник", subtitle: "", group: "instruments", subgroup: "", content: mouthpiece},
    {id: "plx5200a", caption: "PLX5200A", subtitle: "Мобильная цифровая рентгенографическая система", group: "radiology", subgroup: "", content: plx5200a},
    {id: "pld9600d", caption: "PLD9600D", subtitle: "Система цифровой рентгенографии и цифровой флюороскопии", group: "radiology", subgroup: "", content: pld9600d},
    {id: "plx112b1", caption: "PLX112B1", subtitle: "Мобильная цифровая С-дуга", group: "radiology", subgroup: "", content: plx112b1},
    {id: "plx118f", caption: "PLX118F", subtitle: "Мобильная цифровая FPD C-дуга с плоскопанельным детектором", group: "radiology", subgroup: "", content: plx118f},
    {id: "plx7100a", caption: "PLX7100A", subtitle: "Мобильная цифровая интервенционная FPD С-дуга", group: "radiology", subgroup: "", content: plx7100a},
    {id: "mediola_compact", caption: "Mediola Compact", subtitle: "", group: "lasers", subgroup: "", content: mediola_compact},
    {id: "mediola_endo", caption: "Mediola Endo", subtitle: "", group: "lasers", subgroup: "", content: mediola_endo},
    {id: "mediola_holmium", caption: "Mediola Holmium", subtitle: "", group: "lasers", subgroup: "", content: mediola_holmium},
    {id: "u60", caption: "U60", subtitle: "EDAN", group: "ultrasound", subgroup: "Аппараты УЗИ EDAN", content: u60},
    {id: "ax3", caption: "AX3", subtitle: "EDAN Acclarix", group: "ultrasound", subgroup: "Аппараты УЗИ EDAN", content: ax3},
    {id: "lx3", caption: "LX3", subtitle: "EDAN Acclarix", group: "ultrasound", subgroup: "Аппараты УЗИ EDAN", content: lx3},
    {id: "u60", caption: "U60", subtitle: "EDAN", group: "veterinary", subgroup: "", content: u60},
    {id: "ax3", caption: "AX3", subtitle: "EDAN Acclarix", group: "veterinary", subgroup: "", content: ax3},
    {id: "lx3", caption: "LX3", subtitle: "EDAN Acclarix", group: "veterinary", subgroup: "", content: lx3},
    {id: "endoclean1000", caption: "ENDO CLEAN 1000", subtitle: "Установка для мойки и дезинфекции эндоскопов", group: "desinfection", subgroup: "Моечные машины", content: endoclean1000},
    {id: "endoclean2000", caption: "ENDO CLEAN 2000", subtitle: "Установка для мойки и дезинфекции эндоскопов", group: "desinfection", subgroup: "Моечные машины", content: endoclean2000},
    {id: "detrowash8005", caption: "Detro Wash 8005", subtitle: "Автоматическая мойка для гибких эндоскопов", group: "desinfection", subgroup: "Моечные машины", content: detrowash8005},
    // {id: "medinova2ga", caption: "MEDINOVA 2%GA", subtitle: "Средство для обработки эндоскопов", group: "desinfection", subgroup: "Средства для дезинфекции и стрелизации", content: medinova2ga},
    // {id: "medinova_dry", caption: "MEDINOVA DRY", subtitle: "Средство для обработки эндоскопов", group: "desinfection", subgroup: "Средства для дезинфекции и стрелизации", content: medinova_dry},
    // {id: "medinova_enz", caption: "MEDINOVA ENZ", subtitle: "Средство для обработки эндоскопов", group: "desinfection", subgroup: "Средства для дезинфекции и стрелизации", content: medinova_enz},
    {id: "detro_enzym", caption: "DETRO ENZ", subtitle: "Средство для обработки эндоскопов", group: "desinfection", subgroup: "Средства для дезинфекции и стрелизации", content: detro_enzym},
    {id: "detro_forte", caption: "DETRO FORTE", subtitle: "Средство для обработки эндоскопов", group: "desinfection", subgroup: "Средства для дезинфекции и стрелизации", content: detro_forte},
    {id: "detro_opa", caption: "DETRO OPA", subtitle: "Средство для обработки эндоскопов", group: "desinfection", subgroup: "Средства для дезинфекции и стрелизации", content: detro_opa},
    {id: "acecide", caption: "Acecide Powder", subtitle: "Дезинфицирующее средство", group: "desinfection", subgroup: "Средства для дезинфекции и стрелизации", content: acecide},
    {id: "m5exp", caption: "M5exp", subtitle: "УЗИ ZonCare", group: "veterinary", subgroup: "", content: m5exp},
    {id: "viv60", caption: "ViV60", subtitle: "УЗИ ZonCare", group: "veterinary", subgroup: "", content: viv60},
    {id: "viv80s", caption: "ViV80s", subtitle: "УЗИ ZonCare", group: "veterinary", subgroup: "", content: viv80s},
    {id: "colibri", caption: "Mediola Colibri", subtitle: "Oптический хэндпис", group: "lasers", subgroup: "", content: colibri},
    {id: "sololight", caption: "SoloLight 150 / 350 / 600 / 1500", subtitle: "Наконечник для Mediola Colibri", group: "lasers", subgroup: "", content: sololight},
    {id: "duolight", caption: "DuoLight 350 / 600 / 1500", subtitle: "Наконечник для Mediola Colibri", group: "lasers", subgroup: "", content: duolight},
    {id: "unilight", caption: "UniLight 40 / 100 / 200", subtitle: "Наконечник для Mediola Colibri", group: "lasers", subgroup: "", content: unilight},
    {id: "unilightg", caption: "UniLight G 40 / 80", subtitle: "Наконечник для Mediola Colibri", group: "lasers", subgroup: "", content: unilightg},
    {id: "unilighti", caption: "UniLight 400 / 600", subtitle: "Инструмент световодный", group: "lasers", subgroup: "", content: unilighti},
]

export default products;