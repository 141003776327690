const probec611 = () => {
    return (
        <p>
            <br /><b>Радиус:</b> 11 / 14 мм<br /><b>Частота:</b> 4.0-13.0 МГц<br /><b>Количество элементов:</b> 128 элементов<br />
            <b>Клиническое применение:</b> Используется как базовый датчик для диагностики абдоминальной зоны новорожденных
            <br /><br />            
        </p>
    );
}

export default probec611;