const ax3 = () => {
   return (
      <p>  
         В EDAN Acclarix AX3, созданном с целью внедрения значимых инноваций в дизайне, приносящих пользу пользователю, реализовано множество прорывных разработок в области дизайна, которые делают повседневную работу в местах оказания медицинской помощи простой, быстрой и интуитивно понятной. Результатом является элегантная простота, в которой форма и функциональность встречаются на кончиках ваших пальцев.

         В сочетании с инновационной технологией EDAN TAI и несколькими технологиями обработки изображений Acclarix AX3 может идеально отображать ультразвуковое изображение в различных режимах, помогая специалисту ставить более точный диагноз.                      
         <br /><br /><strong>Технические характеристики</strong>
         <br /><br />
         • 15,6-дюймовый ЖК-монитор с высоким разрешением и углом обзора 180 градусов<br />
         • Легкий корпус из магниевого сплава весом 4,5 кг обеспечивает потрясающую мобильность<br />
         • Две батареи, обеспечивающие сверхпродолжительную работу - 2 часа
         • Загрузка в течение 30 секунд<br />
         • 10,1-дюймовый чувствительный сенсорный экран, обеспечивающий эффективную работу<br />
         • eOptimized — оптимизация одним ключом в разных режимах<br />
         • Автоматическая трассировка PW — один из ключевых элементов автоматического измерения<br />
         • Жесткий диск большой емкости с возможностью передачи данных по USB<br />
         • eTouch — эффективное управление жестами<br />
         • Инновационная компоновка пользовательского интерфейса с точным разделением функций        <br />                                        
      </p>
   );
}

export default ax3;