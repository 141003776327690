const p9 = () => {
   return (
      <p>
         <strong>Технические характеристики</strong><br />
         ЖК-монитор с диагональю 21,5 ”;<br />
         3 активных порта и 2 парковочные порты;<br />
         Сенсорная панель управления;<br />
         Выдвижная клавиатура;<br />
         Встроенная аккумуляторная батарея (опция);<br />
         Диапазон рабочих частот: 1-16 МГц;<br />
         USB 2.0, CD / DVD-RW;<br />
         Ethernet, DICOM 3.0.<br />
         <br /><strong>Режимы сканирования</strong><br />
         В, М, В / М, В / В, 4В;<br />
         Тканевая гармоника;<br />
         Импульсно-инверсная гармоника;<br />
         Изменение масштаба изображения в режимах реального времени и стоп-кадра;<br />
         Цветной, энергетический, направленный энергетический, импульсно-волновой доплер, HPRF (регистрация скоростей кровотока);<br />
         Тканевой доплер (опция);<br />
         Дуплексный, триплексный режимы;<br />
         Compound Imaging (режим раскачки УЗ-луча);<br />
         Трапециидальное сканирование на линейных датчиках;<br />
         Анатомический М-режим, цветной М-режим, панорамное сканирование (опция);<br />
         Технология подавления спекл-шума u-Scan.<br />
         <br /><strong>Применение</strong><br />
         Брюшная полость<br />
         Акушерство и гинекология<br />
         Урология<br />
         Щитовидная железа<br />
         Молочная железа<br />
         Кардиология<br />
         Костно-мышечная система<br />
         Педиатрия и неонатология<br />
         Нейросонография<br />
         Исследование сосудов<br />
         Транскраниальные исследования  <br />
         <br /><br />            
      </p>
   );
}

export default p9;