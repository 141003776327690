const tests = () => {
   return (
      <p>                     
         <br /><br />
         <strong>В состав каждого набора входят:</strong><br /><br />
         • Стандартный катридж для Анализатора для определения бактерии хеликобактер пилори (Helicobacter pylori), “Helicobacter pylori Detector” с принадлежностями и расходными материалами модель“HUBT-20P” <br /> 
         <br /> 
         • Капсула с 14С-мочевиной для Анализатора для определения бактерии хеликобактер пилори (Helicobacter pylori), “Helicobacter pylori Detector” с принадлежностями и расходными материалами модель“HUBT-20P”<br /> 
      
         <br />                                        
      </p>
   );
}

export default tests;