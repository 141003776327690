const e1 = () => {
   return (
      <p>
         Ультразвуковой сканер E1 – самый доступный аппарат новой серии E развивает успех популярной ультразвуковой платформы PAD и обеспечивает все необходимые функции диагностики.<br /><br />
         <strong>Технические характеристики</strong><br />
         ЖК-монитор с диагональю 15,6”<br />
         Вес с батареей 6,5 кг<br />
         2 активных порта для подключения датчиков<br />
         автооптимизация параметров изображения нажатием одной кнопки<br />
         проспективная и ретроспективная запись видеоклипов<br />
         диапазон рабочих частот 1-17 МГц<br />                                                     
      </p>
   );
}

export default e1;