const s40exp = () => {
   return (
      <p>
         • 21,5 дюймовый LED монитор высокой четкости с широким углом обзора.<br />
         • 12,1 дюймовая сенсорная панель с мгновенным откликом позволяет точно выбирать клавиши.<br />
         • Новая технология очистки ультразвукового луча, c-field beam и динамическая цветовая технология способствуют исключительному качеству 2D-изображения и чувствительному цветному допплеру.<br />
         • Монокристаллические датчики обеспечивают улучшенное проникновение для трудных пациентов и уменьшают шумы для превосходной детализации.<br />
         • Превосходные объемные датчики и сверхширокоугольные внутриполостные датчики значительно повышают диагностическую достоверность<br />
         • S-Live and S-Depth обеспечивают яркое и реалистичное 4D изображение плода<br />
         • Передовые технологии включают в себя C-xlasto, контраст, TDI, стресс-эхо и другие.<br />
         • Эффективный автоматический расчет и U-style функция значительно повышают эффективность процесса и пропускную способность.<br />
         • Полная база данных пациентов и управление изображениями: DICOM 3.0, USB 3.0, а также подключение Wi-Fi.<br />

         <br /><br />
         Придерживаясь своего отличного качества изображения, SonoScape S40Exp несет в себе комплексную, модернизированную платформу, чтобы стать эпохальной ультразвуковой системой.
         <br />
         Он оснащен мощными монокристаллическими преобразователями, замечательными функциями 4D и интеллектуальным рабочим процессом, который идеально помогает удовлетворить широкий спектр общих потребностей в визуализации.
         <br />
         Оснащенный экстраординарным программным и аппаратным обеспечением, 540Ехр представляет собой идеально сбалансированный дизайн, как внутри, так и снаружи. Быстро реагирующий сенсорный экран, переключение режимов и операции хранения данных обеспечивают новый стандарт с четкостью и гибкостью.                                                       
      </p>
   );
}

export default s40exp;